import { toast } from 'react-toastify';
import axios from 'axios';
import requestTypes from './requestSuiviTypes';
import { SERVER_ADDRESS } from '../../utilities';

const saveRequestSuivi = (request: any, callback: any) => async (dispatch: any, getState: any) => {
    try {
        dispatch({
            type: requestTypes.SAVE_REQUEST_SUIVI
        });
        await axios.post(`${SERVER_ADDRESS}requests-suivi`, request);
        dispatch({
            type: requestTypes.SAVE_REQUEST_SUIVI_DONE
        });
        callback?.();
        toast("Suivi enregistré avec succes");
    } catch (error) {
        dispatch({
            type: requestTypes.SAVE_REQUEST_SUIVI_DONE
        });
        toast("Une erreur est survenue!");
        console.error("create suivi request error", error);
    }
};

const updateRequestSuivi = (request: any, callback: any) => async (dispatch: any, getState: any) => {
    try {
        dispatch({
            type: requestTypes.SAVE_REQUEST_SUIVI
        });
        await axios.put(`${SERVER_ADDRESS}requests-suivi`, request);
        dispatch({
            type: requestTypes.SAVE_REQUEST_SUIVI_DONE
        });
        callback?.();
        toast("Suivi modifié avec succes");
    } catch (error) {
        dispatch({
            type: requestTypes.SAVE_REQUEST_SUIVI_DONE
        });
        toast("Une erreur est survenue!");
        console.error("create suivi request error", error);
    }
};

const getRequestSuivis = () => async (dispatch: any, getState: any) => {
    try {
        const results = await axios.get(`${SERVER_ADDRESS}requests-suivi`);

        dispatch({
            type: requestTypes.SET_REQUESTS_SUIVI,
            payload: results.data,
        });

    } catch (error) {
        toast("Une erreur est survenue!");
        console.log("error", error);
    }
};

export { saveRequestSuivi, getRequestSuivis, updateRequestSuivi };