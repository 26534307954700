import { combineReducers } from 'redux';
import arrondissementReducer from './arrondissement/arrondissementReducer';
import departementReducer from './departement/departementReducer';
import regionReducer from './region/regionReducer';
import requestReducer from './request/requestReducer';
import roleReducer from './roles/roleReducer';
import userReducer from './user/userReducer';
import enterpriseReducer from './enterprise/enterpriseReducer';
import requestSuiviReducer from './requestSuivi/requestSuiviReducer';

const rootReducer = combineReducers({
  requestState: requestReducer,
  regionState: regionReducer,
  departementState: departementReducer,
  arrondissementState: arrondissementReducer,
  userState: userReducer,
  roleState: roleReducer,
  enterpriseState: enterpriseReducer,
  requestSuiviState: requestSuiviReducer,
});

export default rootReducer;