import userTypes from './userTypes';
import { SERVER_ADDRESS } from '../../utilities';
import { toast } from 'react-toastify';

const saveUser = (user: any) => async (dispatch: any, getState: any) => {
    try {
        await fetch(`${SERVER_ADDRESS}users`,
            {
                body: JSON.stringify(user),
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST'
            }
        );
        const results = await fetch(`${SERVER_ADDRESS}users`);
        const data = await results.json()

        dispatch({
            type: userTypes.SET_USERS,
            payload: data,
        });
        toast("Enregistré avec succes");
    } catch (error) {
        toast("Une erreur est survenue!");
        console.error("create user error", error);
    }
};

const getUsers = () => async (dispatch: any, getState: any) => {
    try {
        const results = await fetch(`${SERVER_ADDRESS}users`);
        const data = await results.json()

        dispatch({
            type: userTypes.SET_USERS,
            payload: data,
        });

    } catch (error) {
        console.log("error", error);
    }
};

const deleteUser = (id: string) => async (dispatch: any, getState: any) => {
    try {
        await fetch(`${SERVER_ADDRESS}users/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'DELETE'
            }
        );

        const results = await fetch(`${SERVER_ADDRESS}users`);
        const data = await results.json()

        dispatch({
            type: userTypes.SET_USERS,
            payload: data,
        });

    } catch (error) {
        console.log("error", error);
    }
};

const getLogedInUser = () => async (dispatch: any, getState: any) => {
    try {
        const accessToken = localStorage.getItem('aquacoleAccessToken');
        if (accessToken !== null) {
            const results = await fetch(`${SERVER_ADDRESS}users/find/${accessToken}`);
            const data = await results.json()

            dispatch({
                type: userTypes.SET_LOGGED_IN_USER,
                payload: data,
            });
        }


    } catch (error) {
        console.log("error", error);
    }
};

export { getUsers, saveUser, deleteUser, getLogedInUser };