import React, { useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router";

import { ASDropdown, ASFileInput, ASSidebar, ASTextInput } from "../components";
import { hasRole, yupValidation } from "../utilities";
import { saveRequest } from "../redux/request/requestActions";
import { RootState } from "../redux";
import { Link } from "react-router-dom";
import {
  ACTIVITY_SECTOR,
  // ETAT_FINANCEMENT,
  // REGIONS,
  STATUS_LABEL,
  // TOWNS,
} from "../utilities/constants";

type Props = {};

export const RequestFormScreen: React.FC<Props> = () => {
  // const [loading , setLoading] = useState(false);
  const loggedInUser = useSelector(
    (state: RootState) => state.userState.loggedInUser
  );
  const [showError, setShowError] = useState(false);
  // const [patente, setPatente] = useState([] as any);
  const [businessplan, setBusinessplan] = useState([] as any);


  const enterprises = useSelector(
    (state: RootState) => state.enterpriseState.enterprises.filter((enterprise: any) =>
      hasRole(loggedInUser, ["INSTITUTION"])
        ? enterprise?._user?.id === loggedInUser?.id
        : enterprise
    )
  );

  // const onPatente = (files: any) => {
  //   if (Number(String(files.size).slice(0, -3)) < 2000) {
  //     setPatente(files);
  //     setShowError(false);
  //   } else {
  //     alert(
  //       "Le fichier dépasse 2 Mo, veuillez sélectionner un fichier moins volumineux !"
  //     );
  //   }
  // };

  const onBusinessPlan = (files: any) => {
    if (Number(String(files.size).slice(0, -3)) < 1024) {
      setBusinessplan(files);
      setShowError(false);
    } else {
      alert(
        "Le fichier dépasse 1 Mo, veuillez sélectionner un fichier moins volumineux !"
      );
    }
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useSelector((state: RootState) => state.requestState.loading);

  const { handleSubmit, getFieldProps, errors } = useFormik({
    initialValues: {
      name: "",
      sector: "",
      exposeProjet: "",
      deadlineDelay: "",
      region: "",
      ville: "",
      _enterprise: "",
      projectAmount: "",
      requestAmount: "",
      etatFinancement: "",
      responsableEvaluation: "",
      dateDemarrage: "",
      financeAccode: "",
      previsionRentabilite: "",
      previsionProduction: "",
      address:""
    },
    validationSchema: Yup.object().shape(yupValidation.requestForm),
    onSubmit(values) {
      // setLoading(true)

      if (businessplan.length !== 0) {
        const res = {
          ...values,
          userId:loggedInUser.id,
          patente:"",
          businessplan: businessplan.base64,
        };
        
        dispatch(saveRequest(res, history));
      } else {
        setShowError(true);
      }
      // setLoading(false)
    },
  });

  return (
    <>
      <ASSidebar />
      <div className="main_content px-3 py-3">
        <div className="container text-center py-5">
          <div className="row justify-content-between w-100">
            <h5 className="section-title ml-4">Ajouter un dossier</h5>
            <Link
              to={"/admin/client-files"}
              className="btn btn-outline-secondary"
            >
              <i className="bx bx-left-arrow"></i> Retour
            </Link>
          </div>

          <div
            className="text-left shadow rounded mt-5"
            style={{
              borderTop: "5px solid #1F6299",
              borderBottom: "5px solid #1F6299",
            }}
          >
            <form onSubmit={handleSubmit}>
              <div className="container py-5">
                <div className="row">
                  <ASDropdown
                    title="Entreprise"
                    error={errors._enterprise}
                    options={enterprises}
                    {...getFieldProps("_enterprise")}
                  />
                  <ASTextInput
                    title="Nom du projet"
                    style={{ textTransform: "uppercase" }}
                    error={errors.name}
                    {...getFieldProps("name")}
                  />
                  
                </div>

                {/* <hr />
                <div className="row">
                  <ASDropdown
                    title="Région"
                    error={errors.region}
                    options={REGIONS}
                    dynamic={false}
                    {...getFieldProps("region")}
                  />
                  <ASDropdown
                    title="Ville"
                    error={errors.ville}
                    options={TOWNS}
                    dynamic={false}
                    {...getFieldProps("ville")}
                  />
                </div>
                <hr /> */}
                <div className="row">
                  <ASTextInput
                    title="Adresse de l'entreprise"
                    style={{ textTransform: "uppercase" }}
                    error={errors.address}
                    {...getFieldProps("address")}
                  />
                  <ASDropdown
                    title="Secteur d'activité"
                    error={errors.sector}
                    dynamic={false}
                    options={ACTIVITY_SECTOR}
                    {...getFieldProps("sector")}
                  />
                  <ASTextInput
                    title="Montant global du projet"
                    type="number"
                    error={errors.projectAmount}
                    {...getFieldProps("projectAmount")}
                  />
                  <ASTextInput
                    title="Montant de traite"
                    type="number"
                    error={errors.requestAmount}
                    {...getFieldProps("requestAmount")}
                  />
                  <ASTextInput
                    title="Temps de mensualité (Jours)"
                    type="number"
                    error={errors.deadlineDelay}
                    {...getFieldProps("deadlineDelay")}
                  />
                </div>
                <hr />
                {showError && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="alert alert-warning" role="alert">
                        Veuillez ajouter tous les fichiers ci-dessous
                        {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button> */}
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  {/* <ASFileInput title="Patente" onDone={onPatente.bind(this)} /> */}
                  <ASFileInput
                    title="Business Plan"
                    onDone={onBusinessPlan.bind(this)}
                  />
                </div>
                <hr />
                <div className="row">
                  <ASTextInput
                    title="Date démarrage projet"
                    type="date"
                    error={errors.dateDemarrage}
                    {...getFieldProps("dateDemarrage")}
                  />
                  <ASTextInput
                    title="Responsable évaluation"
                    error={errors.responsableEvaluation}
                    {...getFieldProps("responsableEvaluation")}
                  />
                </div>
                <div className="row">
                  {/* <ASTextInput
                    title="Prévision production"
                    error={errors.previsionProduction}
                    {...getFieldProps("previsionProduction")}
                  />
                  <ASTextInput
                    title="Prévision de rentabilité"
                    error={errors.previsionRentabilite}
                    {...getFieldProps("previsionRentabilite")}
                  /> */}

                </div>
                <div className="row">
                  <ASTextInput
                    title="Financement Accordé (montant)"
                    error={errors.financeAccode}
                    {...getFieldProps("financeAccode")}
                  />
                  <ASDropdown
                    title="État de financement"
                    error={errors.etatFinancement}
                    dynamic={false}
                    options={Object.values(STATUS_LABEL)}
                    {...getFieldProps("etatFinancement")}
                  />
                </div>
                <div className="row w-100">
                  <ASTextInput
                    title="Description du projet"
                    error={errors.exposeProjet}
                    isTextArea
                    {...getFieldProps("exposeProjet")}
                  />
                </div>
                <div className="d-flex justify-content-center mt-5">
                  {!loading && (
                    <button type="submit" className="btn btn-info">
                      Enregistrer
                    </button>
                  )}
                  {loading && (
                    <button
                      type="button"
                      disabled
                      className="btn btn-info px-5"
                    >
                      <i className="fa fa-spinner fa-spin"></i>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
          <a href="https://cybexai.com" target="_blank" className="mt-5">
            <img src="/assets/img/cybex_logo.png" height="60" />
          </a>
        </div>
      </div>
    </>
  );
};
