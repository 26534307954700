import enterpriseTypes from './enterpriseTypes';

const initialState = {
    loading: false,
    enterprises: [],
};

const requestReducer = (state = initialState, action: any) => {
    let nextState;

    switch (action.type) {
        
        case enterpriseTypes.SAVE_ENTERPRISE:
            nextState = {
                ...state,
                loading: true,
            };
            return nextState || state;
        
        case enterpriseTypes.SAVE_ENTERPRISE_DONE:
            nextState = {
                ...state,
                loading: false,
            };
            return nextState || state;
        
        case enterpriseTypes.GET_ENTERPRISES:
            nextState = {
                ...state,
                loading: true,
            };
            return nextState || state;

        case enterpriseTypes.SET_ENTERPRISES:
            nextState = {
                ...state,
                enterprises: action.payload,
                loading: false,
            };
            return nextState || state;
        
        default:
            return state;
    }
};

export default requestReducer;
