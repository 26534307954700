import { toast } from 'react-toastify';
import axios from 'axios';
import requestTypes from './requestTypes';
import { SERVER_ADDRESS } from '../../utilities';

const saveRequest = (request: any, history: any) => async (dispatch: any, getState: any) => {
    try {
        dispatch({
            type: requestTypes.SAVE_REQUEST
        });
        await axios.post(`${SERVER_ADDRESS}requests`, request);
        dispatch({
            type: requestTypes.SAVE_REQUEST_DONE
        });
        history.push('/admin/client-files')
        toast("Demande envoyé avec succes");
    } catch (error) {
        dispatch({
            type: requestTypes.SAVE_REQUEST_DONE
        });
        toast("Une erreur est survenue!");
        console.error("create validation request error", error);
    }
};

const validateRequest = (request: any, history: any) => async () => {
    try {
        await axios.post(`${SERVER_ADDRESS}requests-validate`, request);

        history.push('/admin/requests')
        toast("Requête Validé avec succes!");
    } catch (error) {
        toast("Une erreur est survenue!");
        console.error("valide request error", error);
    }
};

const assignRequest = (request: any, history: any) => async () => {
    try {
        await axios.put(`${SERVER_ADDRESS}requests`, request)

        history.push('/admin/cabinet-juridique')
        toast("Requête Assignée avec succes!");
    } catch (error) {
        toast("Une erreur est survenue!");
        console.error("valide request error", error);
    }
};

const getRequests = () => async (dispatch: any, getState: any) => {
    try {
        const results = await axios.get(`${SERVER_ADDRESS}requests`);

        dispatch({
            type: requestTypes.SET_REQUESTS,
            payload: results.data,
        });

    } catch (error) {
        toast("Une erreur est survenue!");
        console.log("error", error);
    }
};

export { saveRequest, getRequests, validateRequest, assignRequest };