import React from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';

type Props = {}

interface ParamType {
    code: string;
}

export const CodeScreen: React.FC<Props> = () => {

    const { code } = useParams<ParamType>();

    const history = useHistory();

    return (
        <div className="container text-center py-5">
            <Link to="/"><img src="/assets/logo/Logo-1.png" alt="Logo" height="80" width="80" /></Link>
            <h3 className="section-title display-4 mt-4">Code de vérification</h3>
            <p>Utilisez ce code pour vérifier la progression de votre dossier.</p>

            <div className="shadow rounded p-5 mt-5 align-center" style={{ borderTop: '5px solid #1F6299', borderBottom: '5px solid #1F6299' }}>
                <h1 style={{letterSpacing: 20}}>{code}</h1>
                <Link to="/"><button className="btn btn-info bg-success border-0 mt-2">Retour a l'accueil</button></Link>
            </div>
            <a href="https://cybexai.com" target="_blank" className="mt-5"><img src='/assets/img/cybex_logo.png' height='60' /></a>
        </div>
    )
}