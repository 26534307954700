import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ASSidebar, ASEmptyTable } from "../../components";
import ASEnterpriseCard from "../../components/ASEnterpriseCard";
import { RootState } from "../../redux";
import { hasRole } from "../../utilities";
import { ACTIVITY_SECTOR, REGIONS, TOWNS } from "../../utilities/constants";
import { Any } from "../../dataType";

interface Props {}

export const EntrepriseScreen: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [filter , setFilter]  = useState(false)
  const loggedInUser = useSelector(
    (state: RootState) => state.userState.loggedInUser
  );
  const enterprises = useSelector((state: RootState) =>
    state.enterpriseState.enterprises.filter((enterprise: any) =>
      hasRole(loggedInUser, ["ENTERPRISE","INSTITUTION"])
        ? enterprise?._user?.id === loggedInUser?.id
        : enterprise
    )
  );
  // console.log({enterprises})

  // const [currentData, setCurrentData] = useState(enterprises);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedVille, setSelectedVille] = useState("");
  const [selectedTypeDelevage, setSelectedTypeDelevage] = useState("");
  const secteurs = enterprises.map((enterprise: any) => enterprise.sector);


  const onFilter = (e:any) => {
    e.preventDefault();
    // const items = [];
    setFilter(true)
    // if (
    //   selectedRegion !== "" ||
    //   selectedVille !== "" ||
    //   selectedTypeDelevage !== ""
    // ) {
    //   const res = enterprises.filter(
    //     (enterprise: any) =>
    //       selectedRegion !== "" ?
    //       enterprise.region?.toLowerCase() === selectedRegion?.toLowerCase() : enterprise &&
    //       selectedVille !== "" ?
    //       enterprise.ville?.toLowerCase() === selectedVille?.toLowerCase(): enterprise &&
    //       selectedTypeDelevage !== "" ?
    //       enterprise.sector?.toLowerCase() ===
    //         selectedTypeDelevage?.toLowerCase() : enterprise
    //   );
    //   if (res.length) items.push(...res);
    // }
    // if (selectedRegion !== "") {
    //   const res = enterprises.filter(
    //     (enterprise: any) =>
    //       enterprise.region?.toLowerCase() === selectedRegion?.toLowerCase()
    //   );
    //   if (res.length) items.push(...res);
    // }
    // if (selectedVille !== "") {
    //   const res = enterprises.filter(
    //     (enterprise: any) =>
    //       enterprise.ville?.toLowerCase() === selectedVille?.toLowerCase()
    //   );
    //   if (res.length) items.push(...res);
    // }
    // if (selectedTypeDelevage !== "") {
    //   const res = enterprises.filter(
    //     (enterprise: any) =>
    //       enterprise.sector?.toLowerCase() ===
    //       selectedTypeDelevage?.toLowerCase()
    //   );
    //   if (res.length) items.push(...res);
    // }
    // setCurrentData(items);
  };

  const onInitFilter = (e: any) => {
    e.preventDefault();
    setSelectedRegion("");
    setSelectedVille("");
    setSelectedTypeDelevage("");
    setFilter(false)
    // setCurrentData(enterprises);
  };

  const filterData = useMemo(()=>{
    if(filter){
      const d = enterprises.filter((el:Any)=>{
        console.log({region:el?.region, ville:el.ville,secteurs:el.sector});
        const cond1 = el.region?.toLowerCase().includes(selectedRegion?.toLowerCase())
        const cond2 = el?.ville?.toLowerCase().includes(selectedVille?.toLowerCase())
        const cond3 = el?.sector?.toLowerCase().includes(selectedTypeDelevage?.toLowerCase())
        return cond1 && cond2 && cond3
      })
      
      console.log({d})
      return d
    }
    return enterprises
    
  },[enterprises, filter, selectedRegion, selectedTypeDelevage, selectedVille])

  

  const showInitFilter =
    selectedRegion !== "" ||
    selectedTypeDelevage !== "" ||
    selectedVille !== "";

  return (
    <>
      <ASSidebar />
      <div className="main_content px-3 py-3">
        <div className="header-area">
          <h3 className="section-title">Entreprises</h3>
        </div>

        <div className="rounded py-3 mt-4">
          {hasRole(loggedInUser, ["ADMIN","INSTITUTION"]) && (
            <div className="row justify-content-between w-100">
              <div></div>
              <Link to={"/admin/entreprise-form"} className="btn btn-primary">
                Ajouter
              </Link>
            </div>
          )}
          {hasRole(loggedInUser, ["ADMIN","INSTITUTION"]) && (
            <div className="container bg-white">
              <div className="row mt-3 border rounded align-items-center pt-3">
                <div className="form-group">
                  <h6 className="mx-4">Filtrer par:</h6>
                </div>

                <div className="form-group mr-3">
                  <small>Région</small>
                  <select
                    onChange={({ target }) => setSelectedRegion(target.value)}
                    className="form-control"
                    value={selectedRegion}
                  >
                    <option></option>
                    {REGIONS.map((region: any, index: any) => (
                      <option key={index} value={region}>
                        {region}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group mr-3">
                  <small>Ville</small>
                  <select
                    onChange={({ target }) => setSelectedVille(target.value)}
                    className="form-control"
                    value={selectedVille}
                  >
                    <option></option>
                    {TOWNS.map((ville: any, index: any) => (
                      <option key={index} value={ville}>
                        {ville}
                      </option>
                    ))}
                  </select>
                </div>

                {/* <div className="form-group mr-3">
                  <small> Secteur D'activité</small>
                  <select
                    onChange={({ target }) =>
                      setSelectedTypeDelevage(target.value)
                    }
                    value={selectedTypeDelevage}
                    className="form-control"
                  >
                    <option></option>
                    {ACTIVITY_SECTOR.map((secteur: any, index: any) => (
                      <option key={index} value={secteur}>
                        {secteur}
                      </option>
                    ))}
                  </select>
                </div> */}

                <div className="form-group mt-4">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={onFilter}
                  >
                    Filtrer
                  </button>
                  {showInitFilter ? (
                    <button
                      className="btn btn-outline-secondary ml-3"
                      onClick={onInitFilter}
                      type="button"
                    >
                      Reinitialiser le filtrer
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          )}
          <div className="container mt-5">
            {filterData.length !== 0 ? (
              filterData.map((enterprise: any) => (
                <ASEnterpriseCard key={enterprise.id} data={enterprise} />
              ))
            ) : (
              <ASEmptyTable />
            )}
          </div>
        </div>
        <div className="text-center">
          <a href="https://cybexai.com" target="_blank" className="mt-5">
            <img src="/assets/img/cybex_logo.png" height="60" />
          </a>
        </div>
      </div>
    </>
  );
};
