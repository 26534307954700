import React from 'react';
import Modal from "react-bootstrap/Modal";

import ModalFooter from './ModalFooter';


interface Props {
    title?: string;
    error?: string;
    pdf: any;
    modalVisibility: boolean;
    onClose: () => void;
}

export const ASPdfViewerModal: React.FC<Props> = ({ title, error, modalVisibility, onClose, ...props }) => {

    return (
        <Modal centered size="xl"
            show={modalVisibility} onHide={() => onClose()}>
            <Modal.Header closeButton> {title} </Modal.Header>
            <Modal.Body>
                {props.pdf !== '' ? <iframe src={props.pdf} title='Aperçu' 
                    style={{ width: '100%', height: '500px' }}>Error</iframe> : null}

            </Modal.Body>
            <ModalFooter />
        </Modal>
    );

}
