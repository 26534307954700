import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ASRequestCard, ASSidebar, ASEmptyTable, ASDashboardHCard } from '../../components';
import { RootState } from '../../redux';
import { getRequests } from '../../redux/request/requestActions';

interface Props { }

export const AdministrateurScreen: React.FC<Props> = () => {

    const dispatch = useDispatch();
    const requests = useSelector((state: RootState) => state.requestState.requests);
    const pendingRequests = requests.filter(({ _requestStatus }: any) => _requestStatus.name === "PENDING");
    const resolvedRequests = requests.filter(({ _requestStatus }: any) => _requestStatus.name === "RESOLVED");
    const rejectedRequests = requests.filter(({ _requestStatus }: any) => _requestStatus.name === "REJECTED");

    useEffect(() => {
        dispatch(getRequests());
    }, []);

    return (
        <>
            <ASSidebar />
            <div className="main_content px-3 py-3">
                <div className="header-area">
                    <h3 className="section-title">Espace Administrateur</h3>
                </div>

                <div className="mt-5">

                    <div className="row mt-3">
                        <ASDashboardHCard title="Utilisateurs" value="25" icon="group" valueColor="text-success" />
                        <ASDashboardHCard title="Roles" value="25" icon="history" valueColor="text-primary" />
                        <ASDashboardHCard title="Privileges" value="25" icon="trash" valueColor="text-danger" />
                        <ASDashboardHCard title="Demandes Rejetées" value="25" icon="trash" valueColor="text-danger" />
                    </div>
                </div>

                {/* <div className="rounded py-3 mt-4">

                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">En attente</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Acceptées</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Rejetée</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                         <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            {pendingRequests.length !== 0 ? pendingRequests.map((request: any) => <ASRequestCard request={request} priority="high" />) : <ASEmptyTable />}
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            {resolvedRequests.length !== 0 ? resolvedRequests.map((request: any) => <ASRequestCard request={request} priority="high" />) : <ASEmptyTable />}
                        </div>
                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                            {rejectedRequests.length !== 0 ? rejectedRequests.map((request: any) => <ASRequestCard request={request} priority="high" />) : <ASEmptyTable />}
                        </div>
                    </div>
                </div> */}
            </div>

        </>
    )
}
