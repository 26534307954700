import React, { InputHTMLAttributes } from "react";

interface Props extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  title?: string;
  isTextArea?: boolean;
  colSize?: string;
  error?: string;
  type?: string;
}

export const ASTextInput: React.FC<Props> = ({
  title,
  isTextArea,
  colSize = "6",
  error,
  type = "text",
  ...props
}) => {
  return (
    <div className={`col-md-${colSize}`}>
      <div className="md-form mb-3">
        <label className="font-weight-bold text-muted">{title}</label>
        {!isTextArea && (
          <input className="form-control" type={type} {...props} />
        )}
        {isTextArea && <textarea className="form-control" {...props} />}
        {error && <small className="text-danger ml-1">{error}</small>}
      </div>
    </div>
  );
};
