import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from "react-toastify";

import './index.css';
import { index as Navigation } from './navigation';
import reportWebVitals from './reportWebVitals';
import { store } from './redux';
import "react-toastify/dist/ReactToastify.css";

const reduxStore = store;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={reduxStore}>
      <Suspense fallback={null}>
        <Navigation />
      </Suspense>
    </Provider>
    <ToastContainer
        position="top-center"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable={false}
        closeOnClick
        pauseOnHover
      />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
