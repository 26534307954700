import React from 'react';
import Modal from "react-bootstrap/Modal";

const ModalFooter = () => {

    return (
        <Modal.Footer style={{ justifyContent: 'space-between' }}>
            <a href="https://cybexai.com" target="_blank"><img src='/assets/img/cybex_logo.png' height='30' /></a>
            <a href="https://www.atlantiqueassurances.cm" target="_blank"><img src='/assets/img/logo.png' height='30' /></a>
        </Modal.Footer>
    );
}

export default ModalFooter