import React, { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import ModalFooter from "./ModalFooter";
import { ASTextInput } from "./ASTextInput";
import { getRoles } from "../redux/roles/roleActions";
import { RootState } from "../redux";
import { ASDropdown } from "./ASDropdown";
import { saveUser } from "../redux/user/userActions";
import { yupValidation } from "../utilities";
import { Any } from "../dataType";
import { USER_ROLE } from "../utilities/constants";

interface Props {
  title?: string;
  error?: string;
  modalVisibility: boolean;
  onClose: () => void;
  validation?: string;
  requestId?: string;
}

export const ASUserModal: React.FC<Props> = ({
  title,
  error,
  modalVisibility,
  onClose,
  validation,
  requestId,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const roles = useSelector((state: RootState) => state.roleState.roles);
  const loggedInUser = useSelector(
    (state: RootState) => state.userState.loggedInUser
  );
  

  const roleList = useMemo(()=>{
    const adminRole = roles.find((el:Any)=>el?.code=== USER_ROLE.ADMIN)?.id
    // const institutionRole = roles.find((el:Any)=>el?.code=== USER_ROLE.INSTITUTION)?.id
    // const enterpriseRole = roles.find((el:Any)=>el?.code=== USER_ROLE.ENTERPRISE)?.id
    const currentUserRole = loggedInUser?._role?.id

    if(currentUserRole === adminRole){
        return roles.filter((el:Any)=>el?.code=== USER_ROLE.INSTITUTION)
    }
    return roles.filter((el:Any)=>el?.code=== USER_ROLE.ENTERPRISE)
  },[loggedInUser?._role?.id, roles])

  const { handleSubmit, getFieldProps, errors, setFieldValue, resetForm } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        _role:"", 
        pwd: "",
      },
      validationSchema: Yup.object().shape(yupValidation.user),
      onSubmit(values) {
        // tchofsilvere@gmail.com, y4xW88EX
        console.log({...values,userId:loggedInUser.id})
        dispatch(saveUser({...values,userId:loggedInUser.id}));
        onClose();
        resetForm();
        generatePassword();
      },
    });

  const generatePassword = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    setFieldValue("pwd", retVal);
  };

  useEffect(() => {
    dispatch(getRoles());
    generatePassword();
  }, []);

  return (
    <Modal centered size="lg" show={modalVisibility} onHide={() => onClose()}>
      <Modal.Header closeButton> {title} </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit} className="px-0">
          <div className="row w-100">
            <ASTextInput
              title="Nom"
              error={errors.name}
              {...getFieldProps("name")}
            />
            <ASTextInput
              title="Email"
              error={errors.email}
              {...getFieldProps("email")}
            />
          </div>
          <div className="row w-100">
            <ASDropdown
              title="Rôle"
              options={roleList}
              error={errors._role}
              {...getFieldProps("_role")}
            />
            <ASTextInput
              title="Mot de passe"
              error={errors.pwd}
              disabled
              {...getFieldProps("pwd")}
            />
          </div>
          <hr />
          <div className="mx-10">
            <button
              type="button"
              className="btn btn-secondary btn-sm mr-5"
              onClick={() => onClose()}
            >
              {" "}
              Fermer{" "}
            </button>
            <button
              type="submit"
              className="btn btn-sm float-right btn-primary"
            >
              {" "}
              Enregistrer{" "}
            </button>
          </div>
        </form>
      </Modal.Body>
      <ModalFooter />
    </Modal>
  );
};
