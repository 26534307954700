import React from 'react';
import { withRouter, useHistory, RouteComponentProps, Link } from 'react-router-dom';

interface Props {
    title: string;
    icon: string;
    value: string;
    valueColor: string
}

export const ASDashboardHCard: React.FC<Props> = ({ icon, value, title, valueColor, ...props }) => {

    return (
        <div className="col-md-6 mt-5">
            <div
                className="
                bg-white
                rounded
                d-flex
                align-items-center
                justify-content-space-between
                p-3"
            >
                <i className={`bx bx-${icon} text-muted`}
                    style={{ fontSize: '50px' }}></i>
                <div className="ml-3 border-left px-3">
                    <h3 className={`display-4 mb-0 ${valueColor}`}>{value}</h3>
                </div>
                <h5 className="ml-2 text-muted">{title}</h5>
            </div>
        </div>
    )
}