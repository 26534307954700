import React from 'react';

interface Props {
    title?: string;
    value?: string;
    colSize?: string;
}

export const ASRequestDetailItem: React.FC<Props> = ({ title, value, colSize="6", ...props }) => {

    return (
        <div className={`col-md-${colSize}`}>
            <div className="row">
                <div className="col">
                    <p>{title}</p>
                </div>
                <div className="col">
                    <p className="font-weight-normal"><b>{value}</b></p>
                </div>
            </div>
        </div>
    )
}