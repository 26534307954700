import React from "react";

interface Props {
  title?: string;
  data?: any;
  colSize?: string;
  badge?: string;
}

export const ASStatisticCard: React.FC<Props> = ({
  title,
  data,
  colSize = "4",
  badge = "primary",
}) => {
  return (
    <div className={`col-md-${colSize} mt-3`} style={{height:300,overflow:"auto"}}>
      <div className="bg-white rounded py-4">
        {title ? <div className="px-3">
          <h5 className="text-muted">{title}</h5>
        </div> : null}
        <ul className="list-group list-group-flush">
          {data.map((val: any, inx: any) => (
            <li
              key={inx}
              className="
            list-group-item
            d-flex
            justify-content-between
            align-items-center"
            >
              {val.title}
              <span className={`badge badge-${badge} badge-pill`}>{val.value}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
