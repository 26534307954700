import React from 'react';

import { ASSettingCard, ASSidebar } from '../../components';

interface Props {

}

export const SettingScreen: React.FC<Props> = () => {

    // const dispatch = useDispatch();
    // const articles = useSelector((state: RootState) => state.appState.articles);

    // let categories: any = [];

    // if (articles.length !== 0) {

    //     let tempCategories: any = [];

    //     articles.forEach((article: any) => {
    //         tempCategories.push(article._categorie.nom)
    //     });

    //     categories = Array.from(new Set(tempCategories))
    // }

    // useEffect(() => {
    //     dispatch(getArticles());
    // }, []);

    return (
        <>
            <ASSidebar />
            <div className="main_content px-3 py-3">
                <div className="header-area">
                    <h3 className="section-title">Paramètres</h3>
                </div>

                <div className="rounded py-3 mt-4">

                    <div className="row">
                        <ASSettingCard title="Langue" icon="globe" />
                        <ASSettingCard title="Mon Compte" icon="user" />
                        <ASSettingCard title="À propos" icon="info-circle" />
                        <ASSettingCard title="Aide" icon="help-circle" />
                    </div>
                </div>
                <div className="text-center">
                    <a href="https://cybexai.com" target="_blank" className="mt-5"><img src='/assets/img/cybex_logo.png' height='60' /></a>
                </div>
            </div>

        </>
    )
}
