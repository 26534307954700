import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import ModalFooter from "./ModalFooter";
import { validateRequest } from "../redux/request/requestActions";
import { ASFileInput } from "./ASFileInput";
import { RootState } from "../redux";

interface Props {
  title?: string;
  error?: string;
  modalVisibility: boolean;
  onClose: () => void;
  validation?: string;
  requestId?: string;
}

export const ASRequestModal: React.FC<Props> = ({
  title,
  error,
  modalVisibility,
  onClose,
  validation,
  requestId,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [pieceJoint, setPieceJoint] = useState([] as any);
  const loggedInUser = useSelector(
    (state: RootState) => state.userState.loggedInUser
  );

  const onPieceJoint = (files: any) => {
    if (Number(String(files.size).slice(0, -3)) < 1024) {
      setPieceJoint(files);
    } else {
      alert(
        "Le fichier dépasse 1 Mo, veuillez sélectionner un fichier moins volumineux !"
      );
    }
  };

  const { handleSubmit, getFieldProps, errors } = useFormik({
    initialValues: {
      message: "",
    },
    // validationSchema: Yup.object().shape(yupValidation.requestForm),
    onSubmit(values) {
      // if (pieceJoint.length !== 0) {
      const res = {
        ...values,
        id: requestId,
        pieceJoint: pieceJoint.base64,
        _requestStatus: validation,
        _user: loggedInUser.id,
      };
      dispatch(validateRequest(res, history));
      // } else {
      //     alert('Veuillez ajouter une pièce jointe')
      // }
    },
  });

  return (
    <Modal centered show={modalVisibility} onHide={() => onClose()}>
      <Modal.Header closeButton> {title} </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <div className="md-form mb-3 w-100">
                <textarea
                  className="form-control validate"
                  {...getFieldProps("message")}
                  style={{ width: 400 }}
                />
                {/* {error && <small className="text-danger ml-1" > {error} </small>} */}
              </div>
            </div>
            {validation === "6" && (
              <ASFileInput
                title="Pièce joint"
                onDone={onPieceJoint.bind(this)}
              />
            )}
          </div>
          <hr />
          <div className="mt-10">
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm mr-5"
              onClick={() => onClose()}
            >
              {" "}
              Fermer{" "}
            </button>
            <button
              type="submit"
              className="btn btn-sm float-right btn-primary"
            >
              {" "}
              Enregistrer{" "}
            </button>
          </div>
        </form>
      </Modal.Body>
      <ModalFooter />
    </Modal>
  );
};
