const requestSuiviTypes = {
    SAVE_REQUEST_SUIVI: 'SAVE_REQUEST_SUIVI',
    SAVE_REQUEST_SUIVI_DONE: 'SAVE_REQUEST_SUIVI_DONE',
    GET_REQUESTS_SUIVI: 'GET_REQUESTS_SUIVI',
    SET_REQUESTS_SUIVI: 'SET_REQUESTS_SUIVI',
    UPDATE_REQUEST_SUIVI: 'UPDATE_REQUEST_SUIVI',
    DELETE_REQUEST_SUIVI: 'DELETE_REQUEST_SUIVI'
};

export default requestSuiviTypes;
