import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  ASEmptyTable,
  ASFolderCard,
  ASRequestCard,
  ASSidebar,
} from "../../components";
import { RootState } from "../../redux";
import { getRequests } from "../../redux/request/requestActions";
import { hasRole } from "../../utilities";
import { ACTIVITY_SECTOR, REGIONS, TOWNS } from "../../utilities/constants";

interface Props {}

export const ClientFileScreen: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(
    (state: RootState) => state.userState.loggedInUser
  );
  const requests = useSelector(
    (state: RootState) => state.requestState.requests
  );

  const enterprises = useSelector((state: RootState) =>
    state.enterpriseState.enterprises.filter(
      (enterprise: any) => {
        if (hasRole(loggedInUser, ["ENTERPRISE", "INSTITUTION"])) {
          return enterprise?._user?.id === loggedInUser?.id;
        }
        return true;
      }
    )
  );

  // const regions = enterprises.map((enterprise: any) => enterprise.region);
  // const villes = enterprises.map((enterprise: any) => enterprise.ville);
  const secteurs = enterprises.map((enterprise: any) => enterprise.sector);

  console.log({ requests });

  const resolvedRequests = useMemo(() => {
    return requests.filter((req: any) => {
      if (hasRole(loggedInUser, ["ENTERPRISE", "INSTITUTION"])) {
        return req?._enterprise?._user?.id === loggedInUser?.id;
      }
      return true;
    });
  }, [loggedInUser, requests]);
  console.log({ resolvedRequests });

  // const resolvedRequests = requests.filter(({ _requestStatus }: any) => _requestStatus.name === "RESOLVED");

  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);

  const onListView = () => {
    setListView(true);
    setGridView(false);
  };

  const onGridView = () => {
    setListView(false);
    setGridView(true);
  };

  return (
    <>
      <ASSidebar />
      <div className="main_content px-3 py-3">
        <div className="header-area row justify-content-between mx-3 mb-5">
          <h3 className="section-title">Dossiers Project</h3>
        </div>

        <div className="row justify-content-between w-100">
          <div className="row ml-3">
            <button
              className={`btn ${listView && "btn-primary"}`}
              onClick={() => onListView()}
            >
              <i className="bx bx-list-ul"></i>
            </button>
            <button
              className={`btn ${gridView && "btn-primary"}`}
              onClick={() => onGridView()}
            >
              <i className="bx bx-grid-alt"></i>
            </button>
          </div>
          {hasRole(loggedInUser, ["ADMIN", "INSTITUTION"]) && (
            <Link to={"/admin/request-form"} className="btn btn-primary">
              Ajouter
            </Link>
          )}
        </div>
        {hasRole(loggedInUser, ["ADMIN", "INSTITUTION"]) && (
          <div className="container bg-white d">
            <div className="row mt-3 border  rounded align-items-center pt-3">
              <div className="form-group">
                <h6 className="mx-4">Filtrer par:</h6>
              </div>

              {hasRole(loggedInUser, ["ADMIN"]) ? (
                <div className="form-group mr-3">
                  <small>Banque </small>
                  <select className="form-control">
                    <option></option>
                    {REGIONS.map((region: any, index: any) => (
                      <option key={index}>{region}</option>
                    ))}
                  </select>
                </div>
              ) : null}

              <div className="form-group mr-3">
                <small>Région</small>
                <select className="form-control">
                  <option></option>
                  {REGIONS.map((region: any, index: any) => (
                    <option key={index}>{region}</option>
                  ))}
                </select>
              </div>

              <div className="form-group mr-3">
                <small>Adresse</small>
                <select className="form-control">
                  <option></option>
                  {TOWNS.map((ville: any, index: any) => (
                    <option key={index}>{ville}</option>
                  ))}
                </select>
              </div>

              <div className="form-group mr-3">
                <small>Secteur d'activité</small>
                <select className="form-control">
                  <option></option>
                  {ACTIVITY_SECTOR.map((secteur: any, index: any) => (
                    <option key={index}>{secteur}</option>
                  ))}
                  {/* {secteurs.map((secteur: any, index: any) => (
                    <option key={index}>{secteur}</option>
                  ))} */}
                </select>
              </div>

              <div className="form-group mt-4">
                <button className="btn btn-secondary">Filtrer</button>
              </div>
            </div>
          </div>
        )}

        <div className="container">
          {gridView && (
            <div className="row flex-wrap py-3 mt-4">
              {resolvedRequests.length !== 0 ? (
                resolvedRequests.map((request: any, i: number) => (
                  <ASFolderCard request={request} key={i + request?.id} />
                ))
              ) : (
                <ASEmptyTable />
              )}
            </div>
          )}

          {listView && (
            <div className="rounded py-3 mt-4">
              {resolvedRequests.length !== 0 ? (
                resolvedRequests.map((request: any) => (
                  <ASRequestCard request={request} />
                ))
              ) : (
                <ASEmptyTable />
              )}
            </div>
          )}
        </div>
        <div className="text-center">
          <a href="https://cybexai.com" target="_blank" className="mt-5">
            <img src="/assets/img/cybex_logo.png" height="60" />
          </a>
        </div>
      </div>
    </>
  );
};
