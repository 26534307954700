const enterpriseTypes = {
    SAVE_ENTERPRISE: 'SAVE_ENTERPRISE',
    SAVE_ENTERPRISE_DONE: 'SAVE_ENTERPRISE_DONE',
    GET_ENTERPRISES: 'GET_ENTERPRISES',
    SET_ENTERPRISES: 'SET_ENTERPRISES',
    UPDATE_ENTERPRISE: 'UPDATE_ENTERPRISE',
    DELETE_ENTERPRISE: 'DELETE_ENTERPRISE'
};

export default enterpriseTypes;
