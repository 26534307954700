const userTypes = {
    SAVE_USER: 'SAVE_USER',
    GET_USERS: 'GET_USERS',
    SET_USERS: 'SET_USERS',
    UPDATE_USER: 'UPDATE_USER',
    DELETE_USER: 'DELETE_USER',
    SET_LOGGED_IN_USER: 'SET_LOGGED_IN_USER',
};

export default userTypes;
