import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";

import ModalFooter from './ModalFooter';

interface Props {
    title?: string;
    message?: string;
    modalVisibility: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

export const ASRequestConfirmModal: React.FC<Props> = ({ title, message, modalVisibility, onClose, onConfirm, ...props }) => {

    return (
        <Modal centered
            show={modalVisibility} onHide={() => onClose()}>
            <Modal.Header closeButton> {title} </Modal.Header>
            <Modal.Body >
                <div className="text-center">
                    <h4>{message}</h4>
                    <hr />
                   
                </div>
                <div className="mt-10">
                        <button type="button" className="btn btn-outline-secondary btn-sm mr-5" onClick={() => onClose()}> Fermer </button>
                        <button type="button" className="btn btn-sm float-right btn-primary" onClick={() => onConfirm()}> Confirmer </button>
                    </div>
            </Modal.Body>
            <ModalFooter />
        </Modal>
    );

}
