import userTypes from './userTypes';

const initialState = {
    loading: false,
    users: [],
    loggedInUser: [],
};

const userReducer = (state = initialState, action: any) => {
    let nextState;

    switch (action.type) {
        
        case userTypes.GET_USERS:
            nextState = {
                ...state,
                loading: true,
            };
            return nextState || state;

        case userTypes.SET_USERS:
            nextState = {
                ...state,
                users: action.payload,
                loading: false,
            };
            return nextState || state;

        case userTypes.SET_LOGGED_IN_USER:
            nextState = {
                ...state,
                loggedInUser: action.payload,
                loading: false,
            };
            return nextState || state;
        
        default:
            return state;
    }
};

export default userReducer;
