import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { REQUEST_STATUS_COLOR } from "../utilities/constants";

interface Props extends RouteComponentProps<any> {
  title?: string;
  priority?: string;
  bgColor?: string;
  request?: any;
}

const ASFolderCard: React.FC<Props> = ({
  priority,
  bgColor,
  title,
  request,
  ...props
}) => {
  const getState = (state: string) => {
    if (!state) {
      return "folder_orange.svg";
    }
    return REQUEST_STATUS_COLOR[state];
  };

  // console.log(request)

  const formatName = () =>
    String(request.name).length > 20
      ? `${String(request.name).substring(0, 20)}...`
      : request.name;

  return (
    <div className="col-sm-3">
      <div className="item">
        <Link
          to={`/admin/requests/${request.id}`}
          className="d-flex flex-column text-center align-items-center"
        >
          <img
            src={"/assets/img/" + getState(request.etatFinancement)}
            width="150"
            alt="Folder"
          />
          <p className="mb-1 mt-2 text-uppercase">{formatName()}</p>
          <b>{request?._enterprise?.name}</b>
        </Link>
      </div>
    </div>
  );
};

export default withRouter(ASFolderCard);
