import React from 'react';
import FileBase64 from './react-file-base64';

interface Props {
    title: string;
    placeholder?: string;
    onDone: any;
    showMore?: boolean
}

export const ASFileInput: React.FC<Props> = ({ title = 'Title', placeholder = 'Ajouter une photo', showMore, ...other_props }) => {
    return (

        <div className="col-md-6 col-sm-12">
            <div className="md-form my-2">
                <span className="d-block font-weight-bold text-muted mb-2">{title}</span>
                {/* <div className="file-field">
                    <label className="btn btn-light rounded-pill text-dark" data-success="right" htmlFor="file">
                        <i className="fa fa-file mr-2 text-dark"></i>
                        {placeholder}</label>
                </div> */}
                    <FileBase64 {...other_props} />
                    {showMore && <i className={`bx bx-plus-circle ml-2 h5`}></i>}
            </div>
        </div>

    );
}
