import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import "../SigninStyle.css";
import { SERVER_ADDRESS, yupValidation } from "../utilities";
import { toast } from "react-toastify";
import axios from "axios";

type Props = {};

export const SigninScreen: React.FC<Props> = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const onSignin = async (user: any) => {
    try {
      // admin@cybexai.com
      // 3QTHJVUz
      const results = await axios.post(`${SERVER_ADDRESS}auth/signin`,user) 

      const accessToken = await results.data
      localStorage.setItem('aquacoleAccessToken', accessToken);
      history.push("/admin/dashboard")
    } catch (error) {
      toast("Une erreur est survenue!");
      console.error("create user error", error);
    }
  }

  const {
    handleSubmit,
    getFieldProps,
    errors,
    resetForm
  } = useFormik({
    initialValues: {
      email: "", 
      pwd: "",
    },
    validationSchema: Yup.object().shape(yupValidation.signin),
    onSubmit(values) {
      onSignin(values)
      // resetForm()
    }
  });

  return (
    <div className="container-signin">
      <div className="forms-container">
        <div className="signin-signup">
          <form onSubmit={handleSubmit} className="sign-in-form">
            <h2 className="title">Se connecter</h2>

            <div className="form-group">
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-user"></i>
                  </span>
                </div>
                <input className="form-control" placeholder="Email" type="email" {...getFieldProps("email")} />
              </div>
              <small className="text-danger">{errors.email}</small>
            </div>

            <div className="form-group">
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-lock"></i>
                  </span>
                </div>
                <input className="form-control" type="password" placeholder="Mot de passe" {...getFieldProps("pwd")} />
              </div>
              <small className="text-danger">{errors.pwd}</small>
            </div>

            <button type="submit" className="btn btn-info mb-3">Connexion</button>
            <div className="custom-control custom-control-alternative custom-checkbox">
              <input className="custom-control-input" id=" customCheckLogin" type="checkbox" />
              <label className="custom-control-label" htmlFor=" customCheckLogin"><span>Se souvenir de moi</span></label>
            </div>
          </form>
          {/* <div className="row mt-3 text-center">
            <div className="col">
              <a href="#" className="text-info"><small>Mot de passe oublié?</small></a>
            </div>
          </div> */}
        </div>
      </div>

      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <h3> FINANCEMENT </h3>

            <Link to="/" className="w-100"><img src="/assets/logo/logo.png" className="imageimg-fluid rounded w-50 my-2" alt="Logo" /></Link>
            {/* <p>
              Avec Atlantique assurance, la garantie de votre crédit est assurée.
            </p> */}
          </div>
          <img src="/assets/img/log.svg" className="image" alt="" />
        </div>
      </div>
    </div>
  );
};
