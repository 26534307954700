import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const requestForm = {
  name: Yup.string().required("Champ requis"),
  sector: Yup.string().required("Champ requis"),
  address: Yup.string().required("Champ requis"),
  // ville: Yup.string().required("Champ requis"),
  // region: Yup.string().required("Champ requis"),
  deadlineDelay: Yup.number().min(1).required("Délai non valide"),
  _enterprise: Yup.string().required("Champ requis"),
  projectAmount: Yup.number().required("Champ requis"),
  requestAmount: Yup.number()
    .min(500)
    .required("Montant de la demande invalide"),
  exposeProjet: Yup.string().required("Champ requis"),
};

const enterpriseForm = {
  name: Yup.string().required("Champ requis"),
  niu: Yup.string().required("Champ requis"),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Numéro de téléphone invalide")
    .min(8, "trop court")
    .max(9, "trop long")
    .required("Champ requis"),
  ville: Yup.string().required("Champ requis"),
  // rccm: Yup.string().required("Champ requis"),
  region: Yup.string().required("Champ requis"),
  // sector: Yup.string().required("Champ requis"),
  email: Yup.string().email().required("Champ requis"),
  // nuiBank:Yup.number().required("Champ requis"),
};

const requestVerification = {
  code: Yup.number().required("Code invalide"),
  email: Yup.string().email().required("Email invalide"),
};

const user = {
  name: Yup.string().required("Name invalide"),
  email: Yup.string().email().required("Email invalide"),
  _role: Yup.string().required("Rôle invalide"),
};

const signin = {
  email: Yup.string().email().required("Email invalide"),
  pwd: Yup.string().required("Mot de passe invalide"),
};

const asignValidation = {
  _agent: Yup.string().required("Veuillez ajouter un agent"),
};

const suiviForm = {
  dateEvaluation: Yup.string().required("Champ requis"),
  statut: Yup.string().required("Champ requis"),
  etatProduction: Yup.string().required("Champ requis"),
  etatRentabilite: Yup.string().required("Champ requis"),
};

export {
  user,
  signin,
  requestForm,
  requestVerification,
  asignValidation,
  enterpriseForm,
  suiviForm,
};
