import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { hasRole } from "../utilities";
import { ASSidebarButton } from "./ASSidebarButton";

interface Props {}

export const ASSidebar: React.FC<Props> = () => {
  const loggedInUser = useSelector(
    (state: RootState) => state.userState.loggedInUser
  );

  return (
    <div className="sidebar active">
      <div className="logo_content">
        <div className="logo">
          <img src="/assets/logo/logo.png" alt="Logo" />
          <div className="logo_name text-info">
            Espace
            <span className="text-success"> Admin</span>
          </div>
        </div>
        <i className="bx bx-menu" id="sidebar_toggle"></i>
      </div>

      <div className="d-flex align-items-center profile mt-3">
        <div className="profile-pic  rounded-circle">
          <img src="/assets/img/user.png" width="50" />
        </div>
        <div className="profile-info ml-3">
          <h6 className="mb-0">{loggedInUser?.name}</h6>
          <small className="text-info">{loggedInUser?._role?.name}</small>
        </div>
      </div>

      <ul className="nav_list">
        <h6 className="text-muted">Administrer</h6>
        <ASSidebarButton
          title="Tableau de bord"
          icon="chalkboard"
          route="/admin/dashboard"
        />
        {hasRole(loggedInUser, ["ADMIN", "ENTERPRISE","INSTITUTION"]) && (
          <ASSidebarButton
            title="Entreprises"
            icon="box"
            route="/admin/entreprise"
          />
        )}
        {/* <ASSidebarButton title="Les demandes" icon="chat" route="/admin/requests" /> */}
        <ASSidebarButton
          title="Dossier project"
          icon="folder"
          route="/admin/client-files"
        />
      </ul>

      {/* {hasRole(loggedInUser, ["ADMIN"]) && (
        <ul className="nav_list">
          <h6 className="text-muted">Espaces</h6>
          <ASSidebarButton
            title="Statistiques"
            icon="pie-chart-alt-2"
            route="/admin/statistics"
          />
        </ul>
      )} */}

      <ul className="nav_list">
        <h6 className="text-muted">Autres</h6>
        {hasRole(loggedInUser, ["ADMIN","INSTITUTION"]) && <ASSidebarButton
          title="Utilisateurs"
          icon="user"
          route="/admin/users"
          iconClassName="text-success"
        />}
        <ASSidebarButton
          title="Paramètres"
          icon="cog"
          route="/admin/settings"
          iconClassName="text-success"
        />
        <ASSidebarButton
          onClick={() => localStorage.removeItem("aquacoleAccessToken")}
          title="Déconnexion"
          icon="power-off"
          route="/"
          iconClassName="text-danger"
        />
      </ul>
    </div>
  );
};
