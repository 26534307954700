import React from 'react';

interface Props{}

export const ASEmptyTable: React.FC<Props> = () => {

    return (
        <div className='w-100 text-center'>
        <img src='/assets/img/paper.png' width='100' height='100' alt="Empty" />
        <h4 style={{ marginTop: 15 }}>Cette liste est vide</h4>
    </div>
    )
}