import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import ModalFooter from "./ModalFooter";
import { assignRequest } from "../redux/request/requestActions";
import { RootState } from "../redux";
import { getUsers } from "../redux/user/userActions";
import { ASDropdown } from "./ASDropdown";
import { yupValidation } from "../utilities";
import { ASTextInput } from "./ASTextInput";
import { SUIVI_STATUS } from "../utilities/constants";
import {
  saveRequestSuivi,
  updateRequestSuivi,
} from "../redux/requestSuivi/requestSuiviActions";

interface Props {
  title?: string;
  error?: string;
  modalVisibility: boolean;
  onClose: () => void;
  validation?: string;
  request?: any;
  suiviCallback?: any;
  data?: any;
}

export const ASSuiviModal: React.FC<Props> = ({
  title,
  error,
  modalVisibility,
  onClose,
  validation,
  request,
  suiviCallback,
  data,
  ...props
}) => {
  const dispatch = useDispatch();

  const loggedInUser = useSelector(
    (state: RootState) => state.userState.loggedInUser
  );

  const { handleSubmit, getFieldProps, errors, setFieldValue } = useFormik({
    initialValues: {
      dateEvaluation: "",
      statut: "",
      etatProduction: "",
      etatRentabilite: "",
      duree: "",
      pourcentFinRecu: "",
    },
    validationSchema: Yup.object().shape(yupValidation.suiviForm),
    onSubmit(values) {
      const res = {
        ...values,
        _request: request?.id,
      };

      if (data) {
        dispatch(updateRequestSuivi({ ...data, ...res }, suiviCallback));
      } else {
        dispatch(saveRequestSuivi(res, suiviCallback));
      }
    },
  });
  

  const initialize = () => {
    if (data) {
      setFieldValue("dateEvaluation", data?.dateEvaluation);
      setFieldValue("statut", data?.statut);
      setFieldValue("etatProduction", data?.etatProduction);
      setFieldValue("etatRentabilite", data?.etatRentabilite);
      setFieldValue("duree", data?.duree);
      setFieldValue("pourcentFinRecu", data?.pourcentFinRecu);
    }
  };

  useEffect(() => {
    initialize();
  }, []);
  console.log({request})

  return (
    <Modal centered size="lg" show={modalVisibility} onHide={() => onClose()}>
      <Modal.Header closeButton> Ajouter un suivi</Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row w-100">
            <ASTextInput
              title="Date démarrage projet"
              type="date"
              disabled
              value={request?.dateDemarrage}
            />
            <ASTextInput
              title="Date d'évaluation"
              type="date"
              error={errors.dateEvaluation}
              {...getFieldProps("dateEvaluation")}
            />
          </div>
          <div className="row w-100">
            <ASTextInput
              title="Responsable évaluation"
              disabled
              value={request?.responsableEvaluation}
            />
            <ASDropdown
              title="Statut du projet"
              options={SUIVI_STATUS}
              dynamic={false}
              error={errors.statut}
              {...getFieldProps("statut")}
            />
          </div>
          <div className="row w-100">
            <ASTextInput
              title="Montant de traite"
              disabled
              value={request?.requestAmount}
            />
            <ASTextInput
              // title="% production à Date"
              title="Amortissement en date"
              type="number"
              error={errors.etatProduction}
              {...getFieldProps("etatProduction")}
            />
          </div>
          <div className="row w-100">
            {/* <ASTextInput
              title="Prévision de rentabilité"
              disabled
              value={request?.previsionRentabilite}
            /> */}
            <ASTextInput
              title="Temps de mensualité (jours)"
              disabled
              value={request?.deadlineDelay}
            />
            <ASTextInput
              // title="% de rentabilité"
              title="Montant à compte"
              type="number"
              error={errors.etatRentabilite}
              {...getFieldProps("etatRentabilite")}
            />
          </div>
          <div className="row w-100">
            <ASTextInput
              title="Financement Accordé (montant)"
              disabled
              value={request?.financeAccode}
            />
            <ASTextInput
              title="Durée à date"
              error={errors.duree}
              {...getFieldProps("duree")}
            />
          </div>
          {/* <div className="row w-100">
           <div style={{width:"50%"}}></div>
            <ASTextInput
              title="% financement reçu"
              type="number"
              error={errors.pourcentFinRecu}
              {...getFieldProps("pourcentFinRecu")}
            />
          </div> */}

          {/* <div className="row w-100">
          <ASTextInput
              title="Responsable évaluation"
              error={errors.responsableEvaluation}
              {...getFieldProps("responsableEvaluation")}
            />
            <ASTextInput
              title="Date d'évaluation"
              type="date"
              error={errors.dateEvaluation}
              {...getFieldProps("dateEvaluation")}
            />
           
          </div>
          <div className="row w-100">
            <ASTextInput
              title="Prévision production"
              error={errors.previsionProduction}
              {...getFieldProps("previsionProduction")}
            />
            <ASTextInput
              title="Pourcentage de fonds reçu"
              error={errors.etatRentabilite}
              {...getFieldProps("etatRentabilite")}
            />
          </div>
          <div className="row w-100">
            <ASTextInput
              title="Date démarrage projet"
              type="date"
              error={errors.dateDemarrage}
              {...getFieldProps("dateDemarrage")}
            />
            <ASTextInput
              title="Durée projet"
              error={errors.duree}
              {...getFieldProps("duree")}
            />
          </div>
          <div className="row w-100">
            <ASDropdown
              title="Statut du projet"
              options={SUIVI_STATUS}
              dynamic={false}
              error={errors.statut}
              {...getFieldProps("statut")}
            />
            <ASTextInput
              title="État production à Date"
              error={errors.etatProduction}
              {...getFieldProps("etatProduction")}
            />
          </div>
          <div className="row w-100">
              <ASTextInput
              title="Prévision rentabilité"
              error={errors.previsionRentabilite}
              {...getFieldProps("previsionRentabilite")}
            />
            <ASTextInput
              title="% consommation financement"
              error={errors.consommation}
              {...getFieldProps("consommation")}
            />
          </div> */}

          <div className="mt-5">
            <button
              type="button"
              className="btn btn-outline-secondary mr-5 px-5"
              onClick={() => onClose()}
            >
              Fermer
            </button>
            <button type="submit" className="btn float-right btn-primary px-5">
              Enregistrer
            </button>
          </div>
        </form>
      </Modal.Body>
      <ModalFooter />
    </Modal>
  );
};
