import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ASDatatableCard,
  ASEmptyTable,
  ASSidebar,
  ASUserModal,
} from "../../components";
import { RootState } from "../../redux";
import { deleteUser, getUsers } from "../../redux/user/userActions";
import { hasRole } from "../../utilities";
import { Any } from "../../dataType";

interface Props {}

export const UserScreen: React.FC<Props> = () => {
  const loggedInUser = useSelector(
    (state: RootState) => state.userState.loggedInUser
  );
  const dispatch = useDispatch();

  const users = useSelector((state: RootState) =>state.userState.users);
  console.log({users})

  const [modalVisibility, setModalVisibility] = useState(false);
  const [modalTitle, setModalTitle] = useState("Ajouter un utilisateur");

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const onDelete = (id: string) => {
    const result = window.confirm(`Are you sure you want to delete ?`);

    if (result) {
      dispatch(deleteUser(id));
    }
  };

  return (
    <>
      <ASSidebar />
      <div className="main_content px-3 py-3">
        <div className="header-area">
          <h3 className="section-title">Utilisateurs</h3>
        </div>

        <div className="rounded py-3 mt-4">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-outline-primary mb-3"
              onClick={() => setModalVisibility(true)}
            >
              Nouvel utilisateur
            </button>
          </div>

          <div
            className="bg-white p-3 my-3 rounded d-flex justify-content-lg-between align-items-center"
            style={{ overflowX: "auto" }}
          >
            {/* <ASDatatableCard tableData={tableData} columns={columns} /> */}
            {users?.length ? (
              <table className="table table-striped">
                <thead style={{ backgroundColor: "#11994A", color: "#fff" }}>
                  <tr>
                    <th scope="col">Nº</th>
                    <th scope="col">Nom</th>
                    <th scope="col">Email</th>
                    <th scope="col">Rôle</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user: any, index: any) => (
                    <tr key={index+user?.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user._role.name}</td>
                      <td>
                        <button className="btn btn-sm btn-primary" disabled>
                          Modifier
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => onDelete(user.id)}
                        >
                          Supprimer
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <ASEmptyTable />
            )}
          </div>
        </div>
        <div className="text-center">
          <a href="https://cybexai.com" target="_blank" className="mt-5">
            <img src="/assets/img/cybex_logo.png" height="60" />
          </a>
        </div>
        <ASUserModal
          modalVisibility={modalVisibility}
          title={modalTitle}
          onClose={() => setModalVisibility(false)}
        />
      </div>
    </>
  );
};
