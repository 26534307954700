import React from "react";

import { ASSidebar, ASDashboardHCard, ASStatisticCard } from "../../components";

interface Props {}

export const StatisticScreen: React.FC<Props> = () => {
  // const dispatch = useDispatch();
  // const articles = useSelector((state: RootState) => state.appState.articles);

  // let categories: any = [];

  // if (articles.length !== 0) {

  //     let tempCategories: any = [];

  //     articles.forEach((article: any) => {
  //         tempCategories.push(article._categorie.nom)
  //     });

  //     categories = Array.from(new Set(tempCategories))
  // }

  // useEffect(() => {
  //     dispatch(getArticles());
  // }, []);

  return (
    <>
      <ASSidebar />
      <div className="main_content px-3 py-3">
        <div className="header-area">
          <h3 className="section-title">Statistiques</h3>
        </div>

        <div className="mt-5">
          <h5 className="text-muted">Filtrer par:</h5>
          <div className="container">
            <div className="row mt-3 border rounded py-2">
              <div className="col-md-3">
                <div className="form-group">
                  <label>Période du</label>
                  <input
                    type="date"
                    placeholder="yyyy-mm-dd"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>au</label>
                  <input
                    type="date"
                    placeholder="yyyy-mm-dd"
                    className="form-control"
                  />
                </div>
              </div>
           
            </div>
          </div>

          <div className="row mt-3">
            <ASStatisticCard
              title="Demandes par région"
              data={[
                { title: "Yaoundé", value: 147 },
                { title: "Douala", value: 502 },
                { title: "Limbé", value: 51 },
              ]}
            />
            <ASStatisticCard
              title="Demandes par catégorie"
              badge="success"
              data={[
                { title: "Tilapia", value: 0 },
                { title: "Poisson chat", value: 0 },
                { title: "Carpe commune", value: 0 },
                { title: "Autre", value: 0 },
              ]}
            />
            <ASStatisticCard
              title="Demandes par statuts"
              badge="secondary"
              data={[
                { title: "En-cours", value: 0 },
                { title: "En Attente", value: 0 },
                { title: "Terminé", value: 0 },
                { title: "Pas Démarré", value: 0 },
                { title: "Annulé", value: 0 },
              ]}
            />
         
         
            <div className="col-md-4 mt-3">
              <div className="bg-white rounded text-center p-4">
                <div className="px-3">
                  <h3 className="display-2 mb-0 text-info">1920</h3>
                </div>
                <h5 className="text-muted">Nombre total de demandes</h5>
              </div>
            </div>
          </div>
          <div className="text-center">
            <a href="https://cybexai.com" target="_blank" className="mt-5">
              <img src="/assets/img/cybex_logo.png" height="60" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
