import React, { useState } from 'react';
import { SERVER_ADDRESS } from '../utilities';
import { ASPdfViewerModal } from './ASPdfViewerModal';
import { ASRequestItem } from './ASRequestItem';

interface Props {
    request: any
}

export const ASRequestVerifyDetail: React.FC<Props> = ({ request, ...props }) => {

    const [pdfModalVisibility, setPdfModalVisibility] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [pdf, setPdf] = useState('');
   
    const getFile = async (fileId: string, title: string) => {
        const results = await fetch(`${SERVER_ADDRESS}files/${fileId}`);
        const data = await results.json()
        setPdf(data.content)
        setModalTitle(title)
        setPdfModalVisibility(true)
    }

    return (
        <div className="bg-white">
            <div className="my-3">

                {request._requestStatus.name === "PENDING" && <p className="text-info font-weight-bold">
                    <i className="far fa-clock mr-2"></i> Requête en attente de traitement</p>}
                {request._requestStatus.name === "ON_TREATMENT" && <p className="text-primary font-weight-bold">
                    <i className="fa fa-sync-alt mr-2"></i> Requête en
                cours de traitement</p>}
                {request._requestStatus.name === "INCOMPLETE" && <p className="text-warning font-weight-bold">
                    <i className="fa fa-exclamation-circle mr-2"></i> Requête incomplète</p>}
                {request._requestStatus.name === "REJECTED" && <p className="text-danger font-weight-bold">
                    <i className="fa fa-ban mr-2"></i> Requête rejetée</p>}
                {request._requestStatus.name === "RESOLVED" && <p className="text-success font-weight-bold">
                    <i className="fa fa-check-double mr-2"></i> Requête acceptée</p>}
                <p className="text-muted">
                    Bienvenue dans la fenêtre de suivi de vos demandes, vôtre requête est en cours de traitement par
                    nos experts. Merci d'avoir solicité Atlantique Assurance Cameroun IARDT.
          </p>
            </div>
            <hr />
            {request._agent && <>
                <h5>Agent en charge</h5>
                <div className="bg-light rounded px-3 py-3">

                    <div className="d-flex align-items-center">
                        <div className="profile-pic overflow-hidden rounded-circle">
                            <img className="" src="/assets/img/user.png" width="50" />
                        </div>
                        <div className="ml-3">
                            <h6 className="mb-0">{request._agent?.name}</h6>
                            {/* <small className="text-muted">
                            {request.processVerbal[0]?.description}
                        </small> */}
                        </div>
                    </div>
                </div>
            </>}
            <hr />

            <div id="accordion2" className="mt-4">
                <div
                    className="my-3 bg-light rounded"
                    data-toggle="collapse"
                    data-target="#questionOne"
                >
                    <div
                        className="px-4 py-3 shadow-sm rounded"
                        id="headingOne"
                        style={{ borderLeft: '3px solid #17a2b8' }}
                    >
                        <h5 className="mb-0">Vérifier mes informations</h5>
                    </div>

                    <div
                        id="questionOne"
                        className="collapse"
                        aria-labelledby="headingOne">
                        <div className="p-4">
                            <ul className="list-unstyled mt-4 px-2">
                                <ASRequestItem title="Nom de l'entreprise/du client:" value={request.name} />
                                <ASRequestItem title="NIU (Numero Identifiant Unique):" value={request.numeroIdentifiantUnique} />
                                <ASRequestItem title="Numéro de téléphone:" value={request.phoneNumber} />
                                <ASRequestItem title="Numéro de téléphone 2:" value={request.phoneNumber2} />
                                <ASRequestItem title="E-mail:" value={request.email} />
                                <ASRequestItem title="Ville:" value={request.ville} />
                                <ASRequestItem title="Type de demande:" colSize="8" value={request._requestType.name} />
                                <ASRequestItem title="Montant du projet:" colSize="8" value={`${request.projectAmount} FCFA`} />
                                <ASRequestItem title="Montant demandé:" colSize="8" value={`${request.requestAmount} FCFA`} />
                                <ASRequestItem title="Delais d'execution:" colSize="8" value={`${request.deadlineDelay} Jours`} />

                            </ul>
                            <div className="row justify-content-center">
                                <button onClick={() => getFile(request.patente, 'Patente')} className="btn ml-4 btn-sm btn-secondary my-2"><i className="bx bx-file"></i> Patente</button>
                                <button onClick={() => getFile(request.marcheBonCommande, 'Bon de commande')} className="btn ml-3 btn-sm btn-secondary my-2"><i className="bx bx-file"></i> Bon de commande</button>
                                <button onClick={() => getFile(request.cni, "CNI (Carte Nationale d'Identité)")} className="btn ml-3 btn-sm btn-secondary my-2"><i className="bx bx-file"></i> CNI</button>
                                <button onClick={() => getFile(request.businessplan, 'Business Plan')} className="btn ml-3 btn-sm btn-secondary my-2"><i className="bx bx-file"></i> Business Plan</button>
                                <button onClick={() => getFile(request.exposeProjet, 'Exposé Projet')} className="btn ml-3 btn-sm btn-secondary my-2"><i className="bx bx-file"></i> Exposé Projet</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-left mt-3">
                    <button type="button" disabled className="btn btn-info">Modifier ma demande</button>
                </div>
            </div>

            <ASPdfViewerModal pdf={pdf} modalVisibility={pdfModalVisibility} title={modalTitle} onClose={() => setPdfModalVisibility(false)} />
        </div>
    );

}
