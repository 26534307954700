import React from 'react';

interface Props {
    title?: string;
    icon?: string;
    colSize?: string;
}

export const ASSettingCard: React.FC<Props> = ({ title, icon, colSize = "10", ...props }) => {

    return (
        <div className="col-md-6 mt-3">
            <div className="card shadow-sm">
                <div className="card-body d-flex align-items-center">
                    <i className={`bx bx-${icon} bx-md text-muted mb-2 mr-2`}></i>
                    <h1 className="text-muted">{title}</h1>
                </div>
            </div>
        </div>
    )
}