import requestTypes from './requestSuiviTypes';

const initialState = {
    loading: false,
    suivis: [],
};

const requestSuiviReducer = (state = initialState, action: any) => {
    let nextState;

    switch (action.type) {
        
        case requestTypes.SAVE_REQUEST_SUIVI:
            nextState = {
                ...state,
                loading: true,
            };
            return nextState || state;
        
        case requestTypes.SAVE_REQUEST_SUIVI_DONE:
            nextState = {
                ...state,
                loading: false,
            };
            return nextState || state;
        
        case requestTypes.GET_REQUESTS_SUIVI:
            nextState = {
                ...state,
                loading: true,
            };
            return nextState || state;

        case requestTypes.SET_REQUESTS_SUIVI:
            nextState = {
                ...state,
                suivis: action.payload,
                loading: false,
            };
            return nextState || state;
        
        default:
            return state;
    }
};

export default requestSuiviReducer;
