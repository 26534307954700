import roleTypes from './roleTypes';

const initialState = {
    loading: false,
    roles: [],
};

const roleReducer = (state = initialState, action: any) => {
    let nextState;

    switch (action.type) {
        
        case roleTypes.GET_ROLES:
            nextState = {
                ...state,
                loading: true,
            };
            return nextState || state;

        case roleTypes.SET_ROLES:
            nextState = {
                ...state,
                roles: action.payload,
                loading: false,
            };
            return nextState || state;
        
        default:
            return state;
    }
};

export default roleReducer;
