import React, { SelectHTMLAttributes } from 'react';

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
    title?: string;
    error?: string;
    options?: any;
    colSize?: string;
    dynamic?: boolean;
}

export const ASDropdown: React.FC<Props> = ({ title, error, colSize = "6", dynamic = true, options = [{name: 'Test 1'}], ...props }) => {

    return (
        <div className={`col-md-${colSize}`}>
            <div className="md-form mb-3">
                <div className="form-group">
                    <label htmlFor={title} className="font-weight-bold text-muted">{title}</label>
                    <select className="browser-default custom-select" {...props} >
                        <option value="" disabled>Choisir une option</option>
                        {dynamic && options.map((e: any, i: any) => { return <option key={i} value={e.id}>{e.name}</option> })}
                        {!dynamic && options.map((e: any, i: any) => { return <option key={i} value={e}>{e}</option> })}
                    </select>
                    {error && <small className="text-danger ml-1">{error}</small>}
                </div>
            </div>
        </div>
    )
}