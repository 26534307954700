import { Any } from "../dataType";


export const REQUEST_STATUS_COLOR:any = {
  "En traitement": "folder_blue.svg",
  "Accordé": "folder_green.svg",
  "Soldé": "folder_orange.svg",
  "Défiant": "folder_red.svg",
};
export  const USER_ROLE = {
  "ADMIN":"ADMIN",
  "INSTITUTION":"INSTITUTION",
  "ENTERPRISE":"ENTERPRISE",
}


export const ACTIVITY_SECTOR = [
  "Production agropastorale ",
  "Production halleutique ",
  "Agro-industrie",
  "forêt-bois",
  "textile-confection-cuir",
  "artisanat",
  "métallurgie-siderurgie",
  "chimie-pharmacie",
  "construction et immobilier",
  "recyclage , eau et assainissement",
  "numérique ( e-commerce , e-learning)",
  "hôtellerie",
  "tourisme et loisirs",
  "transport et logistiques",
  "restauration"
]
export const ENTERPRISE_STATUS = [
  "Solvable",
  "Endetté",
  "Non Solvable"
]

export const STATUS_LABEL:Any = {
  "PENDING":"En cours",
  "WAITING":"En Attente",
  "SOLD":"soldés",
  "REJECTED":"Annulé"
}

export const PROJECT_STATUS = {
  PENDING: "PENDING",
  WAITING: "WAITING",
  SOLD: "SOLD",
  REJECTED: "REJECTED",
};

export const SUIVI_STATUS = ['En-cours','En Attente','Terminé','Pas Démarré','Annulé']

export const ENTERPRISE_CATERGORY = ['Entreprise Client','Bailleur de fonds',]

export const ETAT_FINANCEMENT = ["En traitement","Accordé","Soldé","Défiant"]

export const REGIONS = ['Adamaoua','Centre','Est','Extrême-Nord','Littoral','Nord','Nord-Ouest','Ouest','Sud','Sud-Ouest']

export const TOWNS = ['Ngaoundéré','Yaoundé','Bertoua','Maroua','Douala','Garoua','Bamenda','Bafoussam','Ebolowa', 'Buéa']