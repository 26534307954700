import React, { InputHTMLAttributes } from 'react';
import { withRouter, useHistory, RouteComponentProps, Link } from 'react-router-dom';
import { COLORS } from '../utilities';

interface Props extends RouteComponentProps<any> {
    title?: string;
    priority?: string;
    bgColor?: string;
    data?: any;
    source?: string;
    onTreatment?: boolean;
    showAgent?: boolean;
}

const ASEnterpriseCard: React.FC<Props> = ({ priority, bgColor, showAgent, data, source, onTreatment }) => {

    return (
        <div className="bg-white p-3 my-3 rounded d-flex justify-content-lg-between align-items-center" style={{ overflowX: 'auto' }}>
            <div className="d-flex align-items-center" style={{width:"30%"}}>
                <i className="bx bx-message-square-detail mr-3 text-info" style={{ fontSize: 36 }}></i>
                <div className="d-flex flex-column">
                    <span className="name mb-0 text-sm font-weight-bold">{data.name}</span>
                    <small className="name mb-0 text-sm text-muted">{data.phoneNumber}</small>
                </div>
            </div>
            {/* <div className="d-flex flex-column" style={{width:"20%"}}>
                <span className="name mb-0 text-sm">Secteur d' activité </span>
                <small className="name mb-0 text-sm text-muted">{data.sector}</small>
            </div> */}
            <div className="d-flex flex-column" style={{width:"10%"}}>
                <span className="name mb-0 text-sm">Région</span>
                <small className="name mb-0 text-sm text-muted">{data.region}</small>
            </div>
            <div className="d-flex flex-column" style={{width:"10%"}}>
                <span className="name mb-0 text-sm">Ville</span>
                <small className="name mb-0 text-sm text-muted">{`${data.ville}`}</small>
            </div>
            <div className="d-flex flex-column justify-content-start" style={{width:"10%"}}>
                <span className="name mb-0 text-sm">NIU</span>
                <span className="badge badge-info mb-1 font-weight-light">{data.niu}</span>
            </div>
            <div className="d-flex justify-content-end">
                <span className="name mb-0 text-sm" >
                    {onTreatment ? <a className="btn btn-outline-info btn-sm rounded-pill disabled">En traitement</a> :
                        <Link to={`/admin/enterprise/${data.id}`} className="btn btn-outline-info btn-sm rounded-pill">Ouvrir</Link>}
                </span>
            </div>
            {/* <span className="name mb-0 text-sm">
            <button className="btn btn-outline-primary btn-sm rounded-pill">Transférer</button>
        </span> */}
        </div>
    )
}

export default withRouter(ASEnterpriseCard)