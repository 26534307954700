import arrondissementTypes from './arrondissementTypes';

const initialState = {
    loading: false,
    arrondissements: [],
};

const arrondissementReducer = (state = initialState, action: any) => {
    let nextState;

    switch (action.type) {
        
        case arrondissementTypes.GET_ARRONDISSEMENTS:
            nextState = {
                ...state,
                loading: true,
            };
            return nextState || state;

        case arrondissementTypes.SET_ARRONDISSEMENTS:
            nextState = {
                ...state,
                arrondissements: action.payload,
                loading: false,
            };
            return nextState || state;
        
        default:
            return state;
    }
};

export default arrondissementReducer;
