import { Button, Modal } from "react-bootstrap";
import { ASDropdown } from "../../../components";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateRequestSuivi } from "../../../redux/requestSuivi/requestSuiviActions";

export default function ModalApreciation({
  selectedId,
  show,
  onClose,
}: {
  selectedId: string;
  show: boolean;
  onClose: () => void;
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    adminNote: "",
    pvStatus: "positive",
  });
  const handleChange = (key: string, val: string) => {
    setData((prev) => ({ ...prev, [key]: val }));
  };
  const callback = () => {
    setLoading(false);
    onClose();
  };
  const onSave = () => {
    setLoading(true);
    dispatch(updateRequestSuivi({ ...data, id: selectedId }, callback));
  };
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title> Appréciation du CNDP</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <ASDropdown
            value={data.pvStatus}
            onChange={(ev) => handleChange("pvStatus", ev.target.value)}
            title="Appréciation"
            options={[{ name: "positive" }, { name: "negative" }]}
            colSize="12"
          />
          <div className="my-3 px-3">
            <label htmlFor="exampleFormControlTextarea1" className="form-label">
              Note
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows={3}
              onChange={(ev) => handleChange("adminNote", ev.target.value)}
            ></textarea>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Annuler
        </Button>
        <Button
          variant="primary"
          onClick={onSave}
          disabled={data.adminNote === "" || loading}
        >
          Enregistrer{" "}
          {loading ? <i className="fa fa-spinner fa-spin"></i> : null}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
