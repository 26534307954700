import { toast } from 'react-toastify';
import roleTypes from './roleTypes';
import { SERVER_ADDRESS } from '../../utilities';

const getRoles = () => async (dispatch: any, getState: any) => {
    try {
        const results = await fetch(`${SERVER_ADDRESS}roles`);
        const data = await results.json()

        dispatch({
            type: roleTypes.SET_ROLES,
            payload: data,
        });

    } catch (error) {
        console.log("error", error);
    }
};

export { getRoles };