import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { UnauthorizedScreen } from '../ui';
import { hasRole } from '../utilities';

interface Props extends RouteProps {
    user: any;
    roles: any;
}

export const ASRestrictedRoute: React.FC<Props> = ({ user, roles, ...props }) => {

        if (hasRole(user, roles)) {
            return <Route {...props} />
        } else {
            // return <Route {...props} />
            return <Route {...props} component={UnauthorizedScreen} />
        }   

}
