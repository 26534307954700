import React, { useEffect } from 'react';
import * as Yup from "yup";
import { useFormik } from "formik";
import Modal from "react-bootstrap/Modal";
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import ModalFooter from './ModalFooter';
import { assignRequest } from '../redux/request/requestActions';
import { RootState } from '../redux';
import { getUsers } from '../redux/user/userActions';
import { ASDropdown } from './ASDropdown';
import { yupValidation } from '../utilities';

interface Props {
    title?: string;
    error?: string;
    modalVisibility: boolean;
    onClose: () => void;
    validation?: string;
    request?: Object;
}

export const ASRequestAssignModal: React.FC<Props> = ({ title, error, modalVisibility, onClose, validation, request, ...props }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const users = useSelector((state: RootState) => state.userState.users);
    const agents = users.filter(({ _role }: any) => _role.code === "AGENT");
    const loggedInUser = useSelector((state: RootState) => state.userState.loggedInUser);

    useEffect(() => {
        dispatch(getUsers());
    }, []);

    const {
        handleSubmit,
        getFieldProps,
        errors,
    } = useFormik({
        initialValues: {
            _agent: ""
        },
        validationSchema: Yup.object().shape(yupValidation.asignValidation),
        onSubmit(values) {
            const res = {
                ...request,
                _requestStatus: "5",
                ...values
            }
            dispatch(assignRequest(res, history))

        }
    });

    return (
        <Modal centered
            show={modalVisibility} onHide={() => onClose()}>
            <Modal.Header closeButton> Assigner la demande à un agent </Modal.Header>
            < Modal.Body >
                <form onSubmit={handleSubmit} className="px-0">
                    <div className="row w-100">
                        <ASDropdown title="Agent" colSize="12" options={agents} error={errors._agent} {...getFieldProps("_agent")} />
                    </div>

                    < div className="mt-10">
                        <button type="button" className="btn btn-outline-secondary btn-sm mr-5" onClick={() => onClose()}> Fermer </button>
                        <button type="submit" className="btn btn-sm float-right btn-primary"> Enregistrer </button>
                    </div>
                </form>
            </Modal.Body>
            <ModalFooter />
        </Modal>
    );

}
