import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const UnauthorizedScreen: React.FC = () => {

    const [loading, setLoading] = useState(true)

    setTimeout(() => {
        setLoading(false);
    }, 9000);

    if (loading) {
        return (<div className="container text-center py-5">
            <i style={{color: '#005993'}} className="fa fa-spinner fa-spin fa-5x"></i>
        </div>)
    } else {
        return (
            <div className="container text-center py-5">
                <Link to="/"><img src="/assets/img/logo.png" alt="Logo" height="80" /></Link>

                <h1 style={{ fontSize: 150, color: '#005993', marginTop: 50 }}>403</h1>
                <p>Vous n'êtes pas autorisé a accéder a cette page!</p>
                <Link to="/" className="btn btn-outline-primary mt-5">Retour à la page d'accueil</Link><br />
                <a href="https://cybexai.com" target="_blank" className="mt-5"><img src='/assets/img/cybex_logo.png' height='60' /></a>
            </div>)
    }

}
