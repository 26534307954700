import React from "react";
import { Link } from "react-router-dom";

interface Props { }

export const HomepageScreen: React.FC<Props> = () => {
  return (
    <>
      <header className="header">
        <div className="overlay has-fade hide-for-desktop"></div>

        <nav className="flex flex-jc-sb flex-ai-c container container--pall">
          <a href="/" className="header__logo logo">
            {/* <i className='bx bxs-bookmark'></i> */}
            <img src="/assets/logo/logo-atlantique-assurances.png" alt="Logo" height="50" />
            {/* <span>Atlantic Assurance</span> */}
          </a>
          <a href="#" id="btnHamburger" className="header__hamburger hide-for-desktop">
            <span></span>
            <span></span>
            <span></span>
          </a>

          <div className="header__links hide-for-mobile">
            {/* <a href="#">Features</a>
      <a href="#">Pricing</a>
      <a href="#">Contact</a> */}
            <Link to='/signin' className="primary-btn btn-blue">Connexion</Link>
          </div>
        </nav>

        <div className="header__menu container--px has-fade hide-for-desktop">
          {/* <a href="#">Features</a>
    <a href="#">Pricing</a>
    <a href="#">Contact</a> */}
          <a href="#">Connexion</a>
        </div>

        <div className="header__social-icons has-fade hide-for-desktop">
          <a href="#">
            <i className='bx bxl-facebook-square'></i>
          </a>
          <a href="#">
            <i className='bx bxl-twitter'></i>
          </a>
        </div>
      </header>

      <section className="hero">
        <div className="container">
          <div className="hero__image"></div>

          <div className="hero__text container--pall">
            <h1>Solliciter une caution en ligne</h1>
            <p>
              Nous sommes des spécialistes des cautions sur prêts.
      </p>
            <Link to="/request-form" className="primary-btn btn-blue">Faire une demande</Link>
            <Link to="/request-verify" className="primary-btn btn-outline-green">Vérifier ma demande</Link>
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="container container--pall">
          <div className="faqs__info">
            <h1>Questions fréquemment posées</h1>
            <p>
              Voici quelques-unes de nos FAQ. Si vous avez d'autres questions auxquelles vous aimeriez
              répondre, n'hésitez pas à nous envoyer un courriel.
      </p>
          </div>

          <div className="faqs__questions">
            <div className="questions__question">
              <input type="radio" name="InpRadio" className="question__input-radio" id="faqsQuestion1" />

              <label htmlFor="faqsQuestion1" className="question__info">
                <h3>Comment obtenir une caution ?</h3>
                <span>
                  <i className='bx bx-chevron-down'></i>
                </span>
              </label>

              <div className="question__text">
                <p>
                  J'ai souscrit a l'assurance et j'ai soumis tout les documents requis j'attends et je me demande comment je vais réceptionné mon prêt !
          </p>
              </div>
            </div>

            <div className="questions__question">
              <input type="radio" name="InpRadio" className="question__input-radio" id="faqsQuestion2" />

              <label htmlFor="faqsQuestion2" className="question__info">
                <h3>J'ai pas eu de réponse à ma requête, que faire?</h3>
                <span>
                  <i className='bx bx-chevron-down'></i>
                </span>
              </label>

              <div className="question__text">
                <p>
                  J'ai solliciter une assurance et j'ai soumis mon dossier met j'ai pas eu de
                  retour, ( le dossier a été traité met le client ne connais pas vérifie l'évolution de son dossier)
          </p>
              </div>
            </div>

            <div className="questions__question">
              <input type="radio" name="InpRadio" className="question__input-radio" id="faqsQuestion3" />

              <label htmlFor="faqsQuestion3" className="question__info">
                <h3>Délai d'attente ?</h3>
                <span>
                  <i className='bx bx-chevron-down'></i>
                </span>
              </label>

              <div className="question__text">
                <p>
                  J'aimerais savoir en combien de temp j'aurais une première reponse concernant ma requête
          </p>
              </div>
            </div>

            <div className="questions__question">
              <input type="radio" name="InpRadio" className="question__input-radio" id="faqsQuestion4" />

              <label htmlFor="faqsQuestion4" className="question__info">
                <h3>Communication et échange avec l'assureur !</h3>
                <span>
                  <i className='bx bx-chevron-down'></i>
                </span>
              </label>

              <div className="question__text">
                <p>
                  La plate-forme permet de consulter l'évolution de votre requête met tout échanges se fera par mail avec l'agent responsable !
          </p>
              </div>
            </div>

            <a href="#" className="faqs__cta">Plus d'informations</a>
          </div>
        </div>
      </section>

      <section className="contact">
        <div className="container container--pall">
          <div className="contact__header">
            <span className="contact__header--subtitle">35,000+ demandes reçues</span>
            <h1 className="contact__header--title">vous aussi faites votre demande.</h1>
          </div>

          <Link to="/request-form" className="primary-btn btn-outline-white">Maintenant!</Link>

          {/* <form className="contact__form">
      <div className="form__input--group form__input--group--email">
        <input className="form__input form__input--email" type="text" name="email" placeholder="Enter your email address"/>
        <span className="form__input--error--icon"><i className='bx bxs-error-circle'></i></span>
        <span className="form__input--error--message"></span>
      </div>

      <div className="form__input--group form__input--group--submit">
        <input className="form__input form__input--submit" type="submit" value="Contact Us"/>
      </div>
    </form> */}
        </div>
      </section>

      <footer className="footer">
        {/* <div className="container container--px"> */}
          {/* <div className="footer__left">
            <a href="/" className="footer__left--logo logo">
              <span>powered by</span>
              <img src="/assets/logo/cybex_logo_white.png" alt="Logo" height="25" />
            </a>

            <ul className="footer__left--links">
              <li><a href="#">Features</a></li>
              <li><a href="#">Pricing</a></li>
              <li><a href="#">Contact</a></li>
            </ul>
          </div> */}
          <div className="row text-center">
          <div className="col-md-12 py-3">
          <a href="https://cybexai.com" target="_blank" className="text-center"><img src='/assets/logo/cybex_logo_white.png' height='50' /></a>
          </div>
          </div>
          {/* <div className="footer__right">
            <div className="footer__right--icons">
              <a href="#"><i className='bx bxl-facebook-square'></i></a>
              <a href="#"><i className='bx bxl-twitter'></i></a>
            </div>
          </div> */}
        {/* </div> */}
      </footer>
    </>
    // <div className="container">

    //     <nav className="navbar navbar-expand-lg navbar-transparent">
    //     <div className="container">
    //     <NavLink to="/" className="navbar-brand" href="#">
    //       <img src="assets/logo/Groupe-8.png" alt="" className="img-fluid w-25"  />
    //     </NavLink>
    //     <div className="ml-auto" id="navbarNav">
    //       <ul className="navbar-nav ml-auto">
    //         <li className="nav-item active">
    //           <a className="nav-link" href="#">
    //           <i className="fas fa-home mr-1"></i>
    //             Home
    //           </a>
    //         </li>
    //         <li className="nav-item ml-3 border-left border-sm-0">
    //             <NavLink to="/signin" className="btn btn-info ml-2" href="#">
    //             Connexion
    //             </NavLink>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    // </nav>



    //   <div className="row" style={{ marginTop: 100 }}>
    //     <div className="col-md-6">
    //       <div className="card shadow mb-1">
    //         <NavLink to="/request-form" >
    //           <div className="card">
    //           <img src="assets/img/banner5.png" className="img-fluid mx-5"/>
    //             <div className="card-body text-center py-2">
    //             Faire une demande
    //             </div>
    //           </div>
    //         </NavLink>            
    //       </div>
    //     </div>
    //     <div className="col-md-6">
    //       <div className="card shadow">
    //         <NavLink to="/admin/dashboard">
    //           <div className="card ">
    //               <img src="assets/img/banner5.png" className="img-fluid mx-5"/>
    //             <div className="card-body text-center py-2">
    //             Dashboard
    //             </div>
    //           </div>
    //         </NavLink>            
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};
