import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ASRestrictedRoute } from '../components';
import { RootState } from '../redux';
import { getEnterprises } from '../redux/enterprise/enterpriseActions';
import { getRequests } from '../redux/request/requestActions';
import { getLogedInUser } from '../redux/user/userActions';
import * as UI from '../ui';


const Index: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector((state: RootState) => state.userState.loggedInUser);
    useEffect(() => {
        dispatch(getLogedInUser());
        dispatch(getEnterprises()); 
        dispatch(getRequests());
    }, [dispatch]);

    const restrictedRoutes = () => {
        if (user?.id) {
            return <>
                <ASRestrictedRoute user={user} roles={['ADMIN', 'ENTERPRISE' ,"INSTITUTION"]}
                    path='/admin/dashboard' component={UI.DashboardScreen} />
                <ASRestrictedRoute user={user} roles={['ADMIN','ENTERPRISE',"INSTITUTION"]}
                    path='/admin/client-files' component={UI.ClientFileScreen} />
                <ASRestrictedRoute user={user} roles={['ADMIN','ENTERPRISE',"INSTITUTION"]}
                    path='/admin/requests/:id' component={UI.RequestDetailScreen} />
                <ASRestrictedRoute user={user} roles={['ADMIN','ENTERPRISE',"INSTITUTION"]}
                    path='/admin/enterprise/:id' component={UI.EnterpriseDetailScreen} />
                <ASRestrictedRoute user={user} roles={['ADMIN','ENTERPRISE',"INSTITUTION"]}
                    path='/admin/entreprise' component={UI.EntrepriseScreen} />
                <ASRestrictedRoute user={user} roles={['ADMIN',"INSTITUTION"]}
                    path='/admin/entreprise-form' component={UI.EnterpriseFormScreen} />
                <ASRestrictedRoute user={user} roles={['ADMIN',"INSTITUTION"]}
                    path='/admin/users' component={UI.UserScreen} />
                <ASRestrictedRoute user={user} roles={['ADMIN',"INSTITUTION"]}
                    path='/admin/administrateur' component={UI.AdministrateurScreen} />
                <ASRestrictedRoute user={user} roles={['ADMIN',"INSTITUTION"]}
                    path='/admin/request-form' component={UI.RequestFormScreen} />
                <ASRestrictedRoute user={user} roles={['ADMIN',"INSTITUTION"]}
                    path='/admin/statistics' component={UI.StatisticScreen} />
            </>
        } 
    }

    return (
        <Router forceRefresh={true}>

            <Switch>
                <Route path='/' exact component={UI.SigninScreen} />
                <Route path='/signin' component={UI.SigninScreen} />
                <Route path='/homepage' component={UI.HomepageScreen} />
                <Route path='/request-form/code/:code' component={UI.CodeScreen} />
                <Route path='/request-verify' component={UI.RequestVerifyScreen} />
                <Route path='/admin/settings' component={UI.SettingScreen} />
                {restrictedRoutes()}
            </Switch>

        </Router>
    );
}

export {Index as index}