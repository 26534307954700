import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  ASRequestDetailItem,
  ASSidebar,
  ASRequestModal,
  ASPdfViewerModal,
  ASSuiviModal,
} from "../../components";
import { hasRole, SERVER_ADDRESS } from "../../utilities";
import { RootState } from "../../redux";
import { ETAT_FINANCEMENT, STATUS_LABEL } from "../../utilities/constants";
import ModalApreciation from "./components/ModalApprecition";
import { Any } from "../../dataType";
import { Alert } from "react-bootstrap";
import ModalStatus from "./components/ModalStatus";

interface Props {}

interface ParamType {
  id: string;
  source: string;
}

export const RequestDetailScreen: React.FC<Props> = () => {
  const { id, source } = useParams<ParamType>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showNote, setShowNote] = useState(false);

  const loggedInUser = useSelector(
    (state: RootState) => state.userState.loggedInUser
  );
  const [modalVisibility, setModalVisibility] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [pdfModalVisibility, setPdfModalVisibility] = useState(false);
  const [suiviModal, setSuiviModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  // const [modalConfirmTitle, setModalConfirmTitle] = useState("");
  // const [modalConfirmMessage, setModalConfirmMessage] = useState("");
  const [request, setRequest] = useState<any>([]);
  const [suivis, setSuivis] = useState<any>([]);
  const [pdf, setPdf] = useState("");
  const [validation, setValidation] = useState("");
  const [inprogress, setInprogress] = useState(false);
  const [selectedSuivi, setSelectedSuivi] = useState<Any | null>(null);

  // const onConfirmValidation = () => {
  //   setModalConfirmTitle("Confirmer la validation");
  //   setModalConfirmMessage(
  //     `Etes-vous sûr de vouloir confirmer la validation faite par l'agent: ${request._agent?.name}`
  //   );
  //   setModalConfirmationVisibility(true);
  // };

  const getRequest = useCallback(async () => {
    const results = await axios.get(`${SERVER_ADDRESS}requests/${id}`);
    setRequest(results.data);
  }, [id]);

  const getRequestSuivi = useCallback(async () => {
    const results = await axios.get(`${SERVER_ADDRESS}requests-suivi/${id}`);
    setSuivis(
      results.data.sort((a: any, b: any) =>
        b.createdAt > a.createdAt ? 1 : -1
      )
    );
  }, [id]);

  // const getFile = async (fileId: string, title: string) => {
  //   const results = await axios.get(`${SERVER_ADDRESS}files/${fileId}`);
  //   setPdf(results.data.content);
  //   setModalTitle(title);
  //   setPdfModalVisibility(true);
  // };

  const suiviCallback = () => {
    setSuiviModal(false);
    getRequestSuivi();
  };

  const onEditSuivi = (suivi: any) => {
    setSelectedSuivi(suivi);
    setSuiviModal(true);
  };

  const onAddAppreciation = (data: any) => {
    setSelectedSuivi(data);
    setShowNote(true);
  };

  useEffect(() => {
    getRequest();
    getRequestSuivi();
  }, [getRequest, getRequestSuivi]);

  function onCloseSuivi(): void {
    setSuiviModal(false);
    setSelectedSuivi(null);
  }
  const openHideNote = () => setShowNote((prev) => !prev);
  console.log({suivis})

  return (
    <>
      <ASSidebar />
      {request.length !== 0 && (
        <div className="main_content px-3 py-3">
          <div className="header-area">
            <h3 className="section-title">Détails du dossier</h3>
          </div>

          <div className="float-right d-flex">
            {/* <select
              placeholder=""
              value={request?.etatFinancement}
              className="browser-default"
              style={{ marginRight: "1rem", paddingInline: "10px" }}
            >
              {ETAT_FINANCEMENT.map((el: string) => (
                <option value={el}>{el} </option>
              ))}
            </select> */}
            {hasRole(loggedInUser, ["ADMIN"]) && (
              <button
                onClick={() => setEditStatus(true)}
                className="btn btn-sm btn-primary mr-4"
              >
                 statut du project
              </button>
            )}
            {hasRole(loggedInUser, ["ADMIN", "INSTITUTION"]) && (
              <button
                onClick={() => setSuiviModal(true)}
                className="btn btn-sm btn-outline-primary mr-4"
              >
                <i className="bx bx-plus"></i> Ajouter un suivi
              </button>
            )}

            <button
              onClick={() => history.goBack()}
              className="btn btn-sm btn-outline-secondary mr-4"
            >
              <i className="bx bx-left-arrow"></i> Retour
            </button>
          </div>

          <div className="rounded py-3 mt-4">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Fiche descriptive
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${inprogress && "bg-secondary"}`}
                  id="pills-inprogress-tab"
                  data-toggle="pill"
                  href="#pills-inprogress"
                  role="tab"
                  aria-controls="pills-incomplete"
                  aria-selected="false"
                >
                  Suivi
                </a>
              </li>
              {/* <li className="nav-item">
                <a
                  className={`nav-link ${resolved && "bg-success"}`}
                  onClick={() => onChangeTab("resolved")}
                  id="pills-profile-tab"
                  data-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Statistiques
                </a>
              </li> */}
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="rounded py-3">
                  <div className="bg-white p-3 my-3 rounded">
                    <div className="row">
                      <ASRequestDetailItem
                        title="Nom de l'entreprise/du client"
                        value={request._enterprise.name}
                      />
                      <ASRequestDetailItem
                        title="NIU (Numero Identifiant Unique)"
                        value={request._enterprise.niu}
                      />
                    </div>

                    <div className="row">
                      <ASRequestDetailItem
                        title="Numéro de téléphone"
                        value={request._enterprise.phoneNumber}
                      />
                      <ASRequestDetailItem
                        title="Secteur d'activité"
                        value={request._enterprise.sector}
                      />
                    </div>

                    <div className="row">
                      <ASRequestDetailItem
                        title="E-mail"
                        value={request._enterprise.email}
                      />
                      <ASRequestDetailItem
                        title="RCCM"
                        value={request._enterprise.rccm}
                      />
                    </div>

                    <div className="row">
                      <ASRequestDetailItem
                        title="Région"
                        value={request._enterprise.region}
                      />
                      <ASRequestDetailItem
                        title="Ville"
                        value={request._enterprise.ville}
                      />
                    </div>
                  </div>
                </div>

                <div className="rounded py-1">
                  <div className="bg-white p-3 my-3 rounded">
                    <div className="row">
                      <ASRequestDetailItem
                        title="Nom du projet"
                        value={request.name}
                      />
                      <ASRequestDetailItem
                        title="Délai de réalisation"
                        value={`${request.deadlineDelay} Jours`}
                      />
                    </div>

                    <div className="row">
                      <ASRequestDetailItem
                        title="Montant de traite"
                        value={`${request.requestAmount} FCFA`}
                      />
                      <ASRequestDetailItem
                        title="Montant global du projet"
                        value={`${request.projectAmount} FCFA`}
                      />
                      <ASRequestDetailItem
                        title="Secteur d'activité (catégorie)"
                        value={request.sector}
                      />
                      <ASRequestDetailItem
                        title="Adresse du projet"
                        value={`${request.region} ${request.ville}`}
                      />
                      <ASRequestDetailItem
                        title="Financement Accordé"
                        value={`${request.financeAccode || 0} FCFA`}
                      />
                      <ASRequestDetailItem
                        title="État de financement"
                        value={request.etatFinancement || "En cours..."}
                      />
                    </div>
                  </div>
                </div>

                <div className="rounded py-1">
                  <div className="bg-white p-3 my-3 rounded">
                    <p>Description du projet</p>
                    <p className="font-weight-normal">
                      <b>{request.exposeProjet}</b>
                    </p>
                  </div>
                </div>

                {/* <div className="rounded py-1">
                  <div className="bg-white p-3 my-3 rounded">
                    <div className="row justify-content-center">
                      {request.patente ? (
                        <button
                          onClick={() => getFile(request.patente, "Patente")}
                          className="btn ml-4 btn-sm btn-secondary my-2"
                        >
                          <i className="bx bx-file"></i> Patente
                        </button>
                      ) : null}
                      {request.businessplan ? (
                        <button
                          onClick={() =>
                            getFile(request.businessplan, "Business Plan")
                          }
                          className="btn ml-3 btn-sm btn-secondary my-2"
                        >
                          <i className="bx bx-file"></i> Business Plan
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div> */}
              </div>

              {/* suivi */}

              <div
                className="tab-pane fade"
                id="pills-inprogress"
                role="tabpanel"
                aria-labelledby="pills-inprogress-tab"
              >
                {suivis?.reverse().map((data: any, inx: number, arr: any) => (
                  <div key={inx} className="rounded py-1">
                    <div className="bg-white p-3 my-3 rounded">
                      <div className="row justify-content-between mx-1">
                        <p>
                          <b>
                          Justificatif d'amortissement  {arr.length - inx} (
                            {new Date(data.creationDate).toDateString()})
                          </b>
                        </p>
                        <div>
                          {hasRole(loggedInUser, ["ADMIN"]) && (
                            <button
                              onClick={() => onEditSuivi(data)}
                              className="btn btn-sm btn-secondary mx-2"
                            >
                              Modifier
                            </button>
                          )}
                          {hasRole(loggedInUser, ["ADMIN"]) && (
                            <button
                              onClick={() => onAddAppreciation(data)}
                              className="btn btn-sm btn-secondary"
                            >
                              Note du CNDP
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <ASRequestDetailItem
                          title="Prévision production"
                          value={request.previsionProduction}
                        />
                        <ASRequestDetailItem
                          title="Etat de production"
                          value={data.etatProduction}
                        />
                        <ASRequestDetailItem
                          title="Responsable évaluation"
                          value={request.responsableEvaluation}
                        />

                        <ASRequestDetailItem
                          title="Pourcentage de fonds reçu"
                          value={data.etatRentabilite}
                        />
                      </div>

                      <div className="row">
                        <ASRequestDetailItem
                          title="Prévision de rentabilité"
                          value={request.previsionRentabilite}
                        />

                        <ASRequestDetailItem
                          title="Statut du projet"
                          value={data.statut}
                        />
                        <ASRequestDetailItem
                          title="Durée projet"
                          value={data.duree}
                        />
                        <ASRequestDetailItem
                          title="Pourcentage de rentabilité"
                          value={`${data.pourcentFinRecu || 0} %`}
                        />
                        <ASRequestDetailItem
                          title="Date démarrage projet"
                          value={new Date(data.dateDemarrage).toDateString()}
                        />
                        <ASRequestDetailItem
                          title="Date d'évaluation"
                          value={new Date(data.dateEvaluation).toDateString()}
                        />
                      </div>
                      <Alert variant={data?.pvStatus === "positive"? "success":"danger"}>
                          Appréciation : {data?.adminNote}
                      </Alert>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="text-center">
            <a href="https://cybexai.com" target="_blank" className="mt-5">
              <img src="/assets/img/cybex_logo.png" height="60" />
            </a>
          </div>
          <ModalApreciation
            selectedId={selectedSuivi?.id as string}
            show={showNote}
            onClose={openHideNote}
          />
        </div>
      )}

      <ASRequestModal
        modalVisibility={modalVisibility}
        requestId={request.id}
        validation={validation}
        title={modalTitle}
        onClose={() => setModalVisibility(false)}
      />
      <ModalStatus show={editStatus} selectedId={request?.id} onClose={()=>setEditStatus(false)} />

      {suiviModal && (
        <ASSuiviModal
          modalVisibility={suiviModal}
          request={request}
          data={selectedSuivi}
          suiviCallback={suiviCallback}
          onClose={onCloseSuivi}
        />
      )}
      <ASPdfViewerModal
        pdf={pdf}
        modalVisibility={pdfModalVisibility}
        title={modalTitle}
        onClose={() => setPdfModalVisibility(false)}
      />
    </>
  );
};
