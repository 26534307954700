import regionTypes from './regionTypes';

const initialState = {
    loading: false,
    regions: [],
};

const regionReducer = (state = initialState, action: any) => {
    let nextState;

    switch (action.type) {
        
        case regionTypes.GET_REGIONS:
            nextState = {
                ...state,
                loading: true,
            };
            return nextState || state;

        case regionTypes.SET_REGIONS:
            nextState = {
                ...state,
                regions: action.payload,
                loading: false,
            };
            return nextState || state;
        
        default:
            return state;
    }
};

export default regionReducer;
