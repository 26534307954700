const requestTypes = {
    SAVE_REQUEST: 'SAVE_REQUEST',
    SAVE_REQUEST_DONE: 'SAVE_REQUEST_DONE',
    GET_REQUESTS: 'GET_REQUESTS',
    SET_REQUESTS: 'SET_REQUESTS',
    UPDATE_REQUEST: 'UPDATE_REQUEST',
    DELETE_REQUEST: 'DELETE_REQUEST'
};

export default requestTypes;
