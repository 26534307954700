import React, { InputHTMLAttributes } from 'react';
import { withRouter, useHistory, RouteComponentProps, Link } from 'react-router-dom';
import { COLORS } from '../utilities';

interface Props extends RouteComponentProps<any> {
    title?: string;
    priority?: string;
    bgColor?: string;
    request?: any;
    source?: string;
    onTreatment?: boolean;
    showAgent?: boolean;
}

const ASRequestCard: React.FC<Props> = ({ priority, bgColor, showAgent, request, source, onTreatment }) => {

    let badgeColor = ''

    const history = useHistory();

    // switch (request._requestPriority.name) {
    //     case 'HIGH':
    //         badgeColor = 'danger'
    //         break;
    //     case 'MEDIUM':
    //         badgeColor = 'warning'
    //         break;
    //     case 'LOW':
    //         badgeColor = 'secondary'
    //         break;

    //     default: badgeColor = 'primary'
    //         break;
    // }

    return (
        <div className="bg-white p-3 my-3 rounded d-flex justify-content-lg-between align-items-center" style={{ overflowX: 'auto' }}>
            <div className="d-flex align-items-center" style={{width:"25%"}}>
                <i className="bx bx-message-square-detail mr-3 text-info" style={{ fontSize: 36 }}></i>
                <div className="d-flex flex-column">
                    <span className="name mb-0 text-sm font-weight-bold">{request.name}</span>
                    <small className="name mb-0 text-sm text-muted">{request._enterprise.phoneNumber}</small>
                </div>
            </div>
            <div className="d-flex flex-column" style={{width:"15%"}}>
                <span className="name mb-0 text-sm"> Secteur d'activité</span>
                <small className="name mb-0 text-sm text-muted">{request.sector}</small>
            </div>
            <div className="d-flex flex-column"style={{width:"15%"}}>
                <span className="name mb-0 text-sm">NIU/Délai</span>
                <small className="name mb-0 text-sm text-muted">{`${request._enterprise.niu} / ${request.deadlineDelay}`}</small>
            </div>
            <div className="d-flex flex-column" style={{width:"15%"}}>
                <span className="name mb-0 text-sm">Adresse</span>
                <small className="name mb-0 text-sm text-muted">{`${request.ville}`}</small>
            </div>
            <div className="d-flex flex-column" style={{width:"15%"}}>
                <span className="name mb-0 text-sm">montant du project</span>
                <span className="badge badge-info mb-1 font-weight-light">{request.requestAmount} FCFA</span>
            </div>
            {request._agent && showAgent && <div className="d-flex flex-column">
                <span className="name mb-0 text-sm">Agent</span>
                <span className="badge badge-primary mb-1 px-3 font-weight-light">{request?.name}</span>
            </div>}
            <span className="name mb-0 text-sm">
                {onTreatment ? <a className="btn btn-outline-info btn-sm rounded-pill disabled">En traitement</a> :
                    <Link to={`/admin/requests/${request.id}`} className="btn btn-outline-info btn-sm rounded-pill">Ouvrir</Link>}
            </span>
            {/* <span className="name mb-0 text-sm">
            <button className="btn btn-outline-primary btn-sm rounded-pill">Transférer</button>
        </span> */}
        </div>
    )
}

export default withRouter(ASRequestCard)