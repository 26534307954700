import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";

import {
  ASRequestDetailItem,
  ASSidebar,
  ASRequestModal,
  ASPdfViewerModal,
  ASRequestAssignModal,
  ASRequestConfirmModal,
} from "../../components";
import { capitalizeFirstLetter, hasRole, SERVER_ADDRESS } from "../../utilities";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { assignRequest } from "../../redux/request/requestActions";

interface Props {}

interface ParamType {
  id: string;
  source: string;
}

export const EnterpriseDetailScreen: React.FC<Props> = () => {
  const { id, source } = useParams<ParamType>();
  const history = useHistory();
  const dispatch = useDispatch();

  const loggedInUser = useSelector(
    (state: RootState) => state.userState.loggedInUser
  );
  const [modalVisibility, setModalVisibility] = useState(false);
  const [modalConfirmationVisibility, setModalConfirmationVisibility] =
    useState(false);
  const [pdfModalVisibility, setPdfModalVisibility] = useState(false);
  const [assignModalVisibility, setAssignModalVisibility] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalConfirmTitle, setModalConfirmTitle] = useState("");
  const [modalConfirmMessage, setModalConfirmMessage] = useState("");
  const [request, setRequest] = useState<any>([]);
  const [pvs, setPvs] = useState<any>([]);
  const [pdf, setPdf] = useState("");
  const [validation, setValidation] = useState("");

  useEffect(() => {
    getRequest();
  }, []);

  const onDeAssignConfirm = () => {
    const updatedRequest = {
      ...request,
      _agent: null,
      _requestStatus: "1",
    };
    dispatch(assignRequest(updatedRequest, history));
  };

  const getRequest = async () => {
    const results = await axios.get(`${SERVER_ADDRESS}enterprises/${id}`);
    setRequest(results.data);
  };

  const getFile = async (fileId: string, title: string) => {
    // console.log("file",title)
    const results = await axios.get(`${SERVER_ADDRESS}files/${fileId}`);
    // console.log(results.data)
    setPdf(results.data.content);
    setModalTitle(title);
    setPdfModalVisibility(true);
  };
  // console.log({request})

  return (
    <>
      <ASSidebar />
      {request.length !== 0 && (
        <div className="main_content px-3 py-3">
          <div className="header-area">
            <h3 className="section-title">Détails de l'entreprise</h3>
          </div>

          <div className="float-right d-flex">
            <button
              onClick={() => history.goBack()}
              className="btn btn-sm btn-outline-secondary mr-4"
            >
              <i className="bx bx-left-arrow"></i> Retour
            </button>
          </div>

          <div className="rounded py-3 mt-4">
            {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  onClick={() => onChangeTab("pending")}
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Fiche descriptive
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${inprogress && "bg-secondary"}`}
                  onClick={() => onChangeTab("inprogress")}
                  id="pills-inprogress-tab"
                  data-toggle="pill"
                  href="#pills-inprogress"
                  role="tab"
                  aria-controls="pills-incomplete"
                  aria-selected="false"
                >
                  Suivi
                </a>
              </li>
            </ul> */}
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="rounded py-1">
                  <div className="bg-white p-3 my-3 rounded">
                    <div className="row">
                      <ASRequestDetailItem
                        title="Nom de L'entreprise"
                        value={`${request.name}`}
                      />
                      {/* <ASRequestDetailItem
                        title="Secteur d'activité"
                        value={request.sector}
                      /> */}
                    </div>

                    <div className="row">
                      <ASRequestDetailItem
                        title="Numéro de téléphone"
                        value={request.phoneNumber}
                      />
                      <ASRequestDetailItem
                        title="E-mail"
                        value={request.email}
                      />
                      <ASRequestDetailItem
                        title="Région"
                        value={request.region}
                      />
                      <ASRequestDetailItem
                        title="Ville"
                        value={request.ville}
                      />
                    </div>

                    <div className="row">
                      {JSON.parse(request?.otherFields || "[]")?.map(
                        (val: any, inx: any) => (
                          <ASRequestDetailItem
                            title={capitalizeFirstLetter(val.title)}
                            value={val.value}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="rounded py-3 mt-4">
                  <div className="bg-white p-3 my-3 rounded">
                    <div className="row">
                      {/* <ASRequestDetailItem
                        title="Catégorie"
                        value={request.categorie}
                      /> */}
                      {/* <ASRequestDetailItem title="RCCM" value={request.rccm} /> */}
                      <ASRequestDetailItem
                        title="NIU (Numero Identifiant Unique)"
                        value={request.niu}
                      />
                      <ASRequestDetailItem
                        title="Mot de passe"
                        value={request.pwd}
                      />
                    </div>
                  </div>
                </div>

                <div className="rounded py-1">
                  <div className="bg-white p-3 my-3 rounded">
                    <div className="row justify-content-center">
                      <button
                        onClick={() =>
                          getFile(
                            request.planLocalisation,
                            "Plan de localisation"
                          )
                        }
                        className="btn ml-4 btn-sm btn-secondary my-2"
                      >
                        <i className="bx bx-file"></i> Plan de localisation
                      </button>
                      <button
                        onClick={() =>
                          getFile(
                            request.immatriculation,
                            "Attestation d'immatriculation"
                          )
                        }
                        className="btn ml-3 btn-sm btn-secondary my-2"
                      >
                        <i className="bx bx-file"></i> Attestation
                        d'immatriculation
                      </button>
                      <button
                        onClick={() =>
                          getFile(
                            request.adBancaire,
                            "Attestation domiciliation bancaire"
                          )
                        }
                        className="btn ml-3 btn-sm btn-secondary my-2"
                      >
                        <i className="bx bx-file"></i> Attestation domiciliation
                        bancaire
                      </button>
                      <button
                        onClick={() =>
                          getFile(
                            request?.planSolvability,
                            "Attestation solvabilité bancaire"
                          )
                        }
                        className="btn ml-3 btn-sm btn-secondary my-2"
                      >
                        <i className="bx bx-file"></i> Attestation de solvabilité
                        bancaire
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-inprogress"
                role="tabpanel"
                aria-labelledby="pills-inprogress-tab"
              >
                <h1>Suivi</h1>
              </div>
            </div>
          </div>

          <div className="text-center">
            <a href="https://cybexai.com" target="_blank" className="mt-5">
              <img src="/assets/img/cybex_logo.png" height="60" />
            </a>
          </div>
        </div>
      )}

      <ASRequestModal
        modalVisibility={modalVisibility}
        requestId={request.id}
        validation={validation}
        title={modalTitle}
        onClose={() => setModalVisibility(false)}
      />
      <ASRequestConfirmModal
        modalVisibility={modalConfirmationVisibility}
        title={modalConfirmTitle}
        message={modalConfirmMessage}
        onConfirm={() => onDeAssignConfirm()}
        onClose={() => setModalConfirmationVisibility(false)}
      />
      <ASRequestAssignModal
        modalVisibility={assignModalVisibility}
        request={request}
        onClose={() => setAssignModalVisibility(false)}
      />
      <ASPdfViewerModal
        pdf={pdf}
        modalVisibility={pdfModalVisibility}
        title={modalTitle}
        onClose={() => setPdfModalVisibility(false)}
      />
    </>
  );
};
