import React, { LiHTMLAttributes } from 'react';
import { NavLink } from 'react-router-dom';

interface Props extends LiHTMLAttributes<HTMLLIElement> {
    title?: string;
    icon?: string;
    active?: boolean,
    route?: string,
    iconClassName?: string
}

export const ASSidebarButton: React.FC<Props> = ({ title, icon, active, route, iconClassName, ...props }) => {

    return (
        <li {...props}>
            <NavLink to={`${route}`} activeClassName={`${route !== '/' && 'active'}`}>
                <i className={`bx bx-${icon} ${iconClassName}`}></i>
                <span className="links_name">{title}</span>
            </NavLink>
            <span className="tooltip">{title}</span>
        </li>
    )
}