import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useHistory } from 'react-router';

import { ASRequestVerifyDetail, ASTextInput } from '../components';
import { SERVER_ADDRESS, yupValidation } from '../utilities';
import { Link } from 'react-router-dom';
import axios from 'axios';

type Props = {

}

export const RequestVerifyScreen: React.FC<Props> = () => {

    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState<any>([]);

    useEffect(() => {
    }, []);

    const getRequest = async (code: string, email: string) => {
        const results = await axios.get(`${SERVER_ADDRESS}requests-check/${code}/${email}`); 
        setRequest(results.data)
        setIndex(1)
    }

    const {
        handleSubmit,
        getFieldProps,
        errors,
    } = useFormik({
        initialValues: {
            code: "", email: ""
        },
        validationSchema: Yup.object().shape(yupValidation.requestVerification),
        onSubmit(values) {
            // setLoading(true)
            getRequest(values.code, values.email);

            setLoading(false)
        }
    });

    return (
        <div className="container text-center py-5">
            <Link to="/"><img src="/assets/logo/Logo-1.png" alt="Logo" height="80" width="80" /></Link>
            <h3 className="section-title display-4 mt-4">Vérifier l'état de ma demande</h3>

            {/* <p>Bienvenue dans la fenêtre de suivi de vos demandes, vôtre requête est en traitement par </p>
                <p>nos experts merci d'avoir soliciter Atlantique Assurance</p> */}

            <div className="text-left shadow rounded p-5 mt-5" style={{ borderTop: '5px solid #1F6299', borderBottom: '5px solid #1F6299' }}>
                {index === 0 && <form onSubmit={handleSubmit}>
                    <div className="">
                        <div className="row">
                            <ASTextInput title="E-mail" error={errors.email} {...getFieldProps("email")} />
                            <ASTextInput title="Code" error={errors.code} {...getFieldProps("code")} />
                        </div>

                        <hr />
                        <small>Pour vérifier l'état de votre demande, veuillez saisir votre code et votre email</small>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <button type="submit" className="btn btn-info">Vérifier</button>
                    </div>
                </form>}
                {index === 1 && request && <ASRequestVerifyDetail request={request} />}
            </div>
            <a href="https://cybexai.com" target="_blank" className="mt-5"><img src='/assets/img/cybex_logo.png' height='60' /></a>
        </div>

    )
}