import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { RootState } from "../../redux";
import { yupValidation } from "../../utilities";
import {
  ASDropdown,
  ASFileInput,
  ASSidebar,
  ASTextInput,
} from "../../components";
import {
  ACTIVITY_SECTOR,
  ENTERPRISE_CATERGORY,
  ENTERPRISE_STATUS,
} from "../../utilities/constants";
import { getEnterprises, saveEnterprise } from "../../redux/enterprise/enterpriseActions";

type Props = {};

export const EnterpriseFormScreen: React.FC<Props> = () => {

  const loggedInUser = useSelector(
    (state: RootState) => state.userState.loggedInUser
  );
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [planLocalisation, setPlanLocalisation] = useState([] as any);
  const [immatriculation, setImmatriculation] = useState([] as any);
  const [adBancaire, setAdBancaire] = useState([] as any);
  const [otherFields, setOtherFields] = useState({} as any);
  const [planSolvability ,setPlanSolvability] = useState([] as any);

  const addField = (e: any) => {
    e.preventDefault();
    setOtherFields((prev: any) => ({
      ...prev,
      [Object.keys(otherFields).length + 1]: {
        title: "",
        value: "",
        inx: Object.keys(otherFields).length + 1,
      },
    }));
  };

  const onPlanLocalisation = (files: any) => {
    if (Number(String(files.size).slice(0, -3)) < 2000) {
      setPlanLocalisation(files);
      setShowError(false);
    } else {
      alert(
        "Le fichier dépasse 2 Mo, veuillez sélectionner un fichier moins volumineux !"
      );
    }
  };

  const onImmatriculation = (files: any) => {
    if (Number(String(files.size).slice(0, -3)) < 1024) {
      setImmatriculation(files);
      setShowError(false);
    } else {
      alert(
        "Le fichier dépasse 1 Mo, veuillez sélectionner un fichier moins volumineux !"
      );
    }
  };

  const onAdBancaire = (files: any) => {
    if (Number(String(files.size).slice(0, -3)) < 1024) {
      setAdBancaire(files);
      setShowError(false);
    } else {
      alert(
        "Le fichier dépasse 1 Mo, veuillez sélectionner un fichier moins volumineux !"
      );
    }
  };
  const onAddPlanSolvability = (files: any) => {
    if (Number(String(files.size).slice(0, -3)) < 1024) {
      setPlanSolvability(files);
      setShowError(false);
    } else {
      alert(
        "Le fichier dépasse 1 Mo, veuillez sélectionner un fichier moins volumineux !"
      );
    }
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const callback = () => {
    setLoading(false);
    dispatch(getEnterprises()); 
    history.goBack();
  };
  const onError = () => {
    setLoading(false);
  };

  const { handleSubmit, getFieldProps, setFieldValue, errors } = useFormik({
    initialValues: {
      name: "",
      niu: "",
      phoneNumber: "",
      ville: "",
      rccm: "",
      region: "",
      sector: "",
      email: "",
      pwd: "",
      categorie: "",
      financeAmount: "",
      nuiBank:"",
      enterpriseStatus:"",
    },
    validationSchema: Yup.object().shape(yupValidation.enterpriseForm),
    onSubmit(values) {
      // console.log({values})
      

      if (
        planLocalisation.length !== 0 &&
        immatriculation.length !== 0 &&
        adBancaire.length !== 0 &&
        planSolvability?.length !== 0
      ) {
        setLoading(true);
        const data = {
          ...values,
          userId:loggedInUser?.id,
          planLocalisation: planLocalisation.base64,
          immatriculation: immatriculation.base64,
          adBancaire: adBancaire.base64,
          planSolvability:planSolvability.base64,
          otherFields: JSON.stringify(Object.values(otherFields)),
        };
        console.log({enterprise:data})

        dispatch(saveEnterprise({ data, callback, onError }));
      } else {
        setShowError(true);
      }
      
    },
  });

  const generatePassword = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    setFieldValue("pwd", retVal);
  };

  useEffect(() => {
    generatePassword();
  }, []);

  function hangleTextChange(key: string, inx: number, value: string): void {
    setOtherFields((prev: any) => ({
      ...prev,
      [inx]: {
        ...otherFields[inx],
        [key]: value,
      },
    }));
  }

  function removeField(inx: any): void {
    let tempVal = otherFields;
    delete tempVal[inx];
    setOtherFields(tempVal);
  }

  return (
    <>
      <ASSidebar />
      <div className="main_content px-3 py-3">
        <div className="container text-center py-5">
          <div className="row justify-content-between w-100">
            <h5 className="section-title ml-4">Enregistrer une entreprise</h5>
            <Link
              to={"/admin/entreprise"}
              className="btn btn-outline-secondary"
            >
              <i className="bx bx-left-arrow"></i> Retour
            </Link>
          </div>

          <div
            className="text-left shadow rounded mt-5"
            style={{
              borderTop: "5px solid #1F6299",
              borderBottom: "5px solid #1F6299",
            }}
          >

            <form onSubmit={handleSubmit}>
              <div className="container py-5">
                <div className="row">
                  <ASTextInput
                    title="Nom de l'entreprise"
                    error={errors.name}
                    {...getFieldProps("name")}
                  />
                  <ASTextInput
                    title="NIU (Numero Identifiant Unique)"
                    style={{ textTransform: "uppercase" }}
                    error={errors.niu}
                    {...getFieldProps("niu")}
                  />
                  {/* <ASTextInput
                    title="RCCM"
                    error={errors.rccm}
                    {...getFieldProps("rccm")}
                  /> */}
                  <ASTextInput
                    title="Numéro de téléphone"
                    placeholder="ex: 699197658"
                    // type="number"
                    error={errors.phoneNumber}
                    {...getFieldProps("phoneNumber")}
                  />
                  <ASTextInput
                    title="E-mail"
                    error={errors.email}
                    {...getFieldProps("email")}
                  />
                </div>

                {/* <hr />
                <div className="row">
                  
                  <ASTextInput
                    title="Mot de passe"
                    disabled
                    {...getFieldProps("pwd")}
                  />
                </div>
                <hr /> */}
                <div className="row">
                  <ASTextInput
                    title="Ville"
                    error={errors.ville}
                    {...getFieldProps("ville")}
                  />
                  <ASTextInput
                    title="Region"
                    error={errors.region}
                    {...getFieldProps("region")}
                  />
                  {/* <ASDropdown
                    title="Secteur d'activité"
                    error={errors.sector}
                    dynamic={false}
                    options={ACTIVITY_SECTOR}
                    {...getFieldProps("sector")}
                  /> */}
                  <ASTextInput
                    title="Numéro d'identifiant de la branche bancaire"
                    // type="number"
                    error={errors.nuiBank}
                    {...getFieldProps("nuiBank")}
                  />
                   {/* <ASTextInput
                    title="Montant a financer"
                    type="number"
                    error={errors.financeAmount}
                    {...getFieldProps("financeAmount")}
                  /> */}
                  {/* <ASDropdown
                    title="status de l'entreprise"
                    error={errors.enterpriseStatus}
                    dynamic={false}
                    options={ENTERPRISE_STATUS}
                    {...getFieldProps("enterpriseStatus")}
                  /> */}
                </div>
                <div className="row">
             
                  {Object.values(otherFields).map((val: any, index) => (
                    <div key={index} className="col-md-6">
                      <ASTextInput
                        title={`Titre (${val.inx})`}
                        value={val.title}
                        colSize="12"
                        onChange={(e) =>
                          hangleTextChange("title", val.inx, e.target.value)
                        }
                      />
                      <ASTextInput
                        title={`Valeur (${val.inx})`}
                        value={val.value}
                        colSize="12"
                        onChange={(e) =>
                          hangleTextChange("value", val.inx, e.target.value)
                        }
                      />
                      <button
                        className="btn btn-danger btn-sm mx-3 mb-4"
                        onClick={() => removeField(val.inx)}
                      >
                        Retirer {val.inx}
                      </button>
                    </div>
                  ))}
                </div>
                <button className="btn btn-secondary btn-sm" onClick={addField}>
                  Ajouter une entrée
                </button>

                <hr />
                {showError && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="alert alert-warning" role="alert">
                        Veuillez ajouter tous les fichiers ci-dessous
                        {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button> */}
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <ASFileInput
                    title="Plan de localisation"
                    onDone={onPlanLocalisation.bind(this)}
                  />
                  <ASFileInput
                    title="Attestation d'immatriculation"
                    onDone={onImmatriculation.bind(this)}
                  />
                  <ASFileInput
                    title="Attestation domiciliation bancaire"
                    onDone={onAdBancaire.bind(this)}
                  />
                  <ASFileInput
                    title="Registre de commerce "
                    onDone={onAddPlanSolvability.bind(this)}
                  />
                </div>

                <div className="d-flex justify-content-center mt-5">
                  {!loading && (
                    <button type="submit" className="btn btn-info px-5">
                      Enregistrer
                    </button>
                  )}
                  {loading && (
                    <button
                      type="button"
                      disabled
                      className="btn btn-info px-5"
                    >
                      <i className="fa fa-spinner fa-spin"></i>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
          <a href="https://cybexai.com" target="_blank" className="mt-5">
            <img src="/assets/img/cybex_logo.png" height="60" />
          </a>
        </div>
      </div>
    </>
  );
};
