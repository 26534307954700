import { toast } from 'react-toastify';
import axios from 'axios';
import enterpriseTypes from './enterpriseTypes';
import { SERVER_ADDRESS } from '../../utilities';

const saveEnterprise = ({data, callback, onError}: any) => async (dispatch: any, getState: any) => {
    try {
        dispatch({
            type: enterpriseTypes.SAVE_ENTERPRISE
        });
        await axios.post(`${SERVER_ADDRESS}enterprises`, data);
        dispatch({
            type: enterpriseTypes.SAVE_ENTERPRISE_DONE
        });
        callback?.()
        toast("Entreprise crée avec succes");
    } catch (error) {
        onError?.()
        toast("Une erreur est survenue!");
        console.error("enterprise creation error", error);
    }
};

const getEnterprises = () => async (dispatch: any, getState: any) => {
    try {
        const results = await axios.get(`${SERVER_ADDRESS}enterprises`);

        dispatch({
            type: enterpriseTypes.SET_ENTERPRISES,
            payload: results.data,
        });

    } catch (error) {
        toast("Une erreur est survenue!");
        console.log("error", error);
    }
};

const deleteEnterprise = (id: string) => async (dispatch: any, getState: any) => {
    try {
        const results = await axios.delete(`${SERVER_ADDRESS}enterprises/${id}`);

        // dispatch({
        //     type: enterpriseTypes.SET_ENTERPRISES,
        //     payload: data,
        // });

    } catch (error) {
        console.log("error", error);
    }
};

export { saveEnterprise, getEnterprises, deleteEnterprise };