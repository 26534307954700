import React from 'react';

interface Props {
    title?: string;
    value?: string;
    colSize?: string;
}

export const ASRequestItem: React.FC<Props> = ({ title, value, colSize = "8", ...props }) => {

    return (
        <li className="list-item my-2">
            <div className="row">
                <div className="col-md-4">
                    <h6 className="text-muted">{title}</h6>
                </div>
                <div className={`col-md-${colSize}`}>
                    <p><b>{value}</b></p>
                </div>
            </div>
        </li>
    )
}