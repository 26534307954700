import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import millify from "millify";
import { ASSidebar, ASDashboardHCard, ASStatisticCard } from "../../components";
import { RootState } from "../../redux";
import { Any } from "../../dataType";
import { hasRole } from "../../utilities";
import { ACTIVITY_SECTOR, PROJECT_STATUS, STATUS_LABEL } from "../../utilities/constants";

interface Props {}

export const DashboardScreen: React.FC<Props> = () => {
  
  const loggedInUser = useSelector(
    (state: RootState) => state.userState.loggedInUser
  );

  const enterprises = useSelector((state: RootState) =>
    state.enterpriseState.enterprises.filter((enterprise: any) => {
      if (hasRole(loggedInUser, ["ENTERPRISE","INSTITUTION"])) {
        return enterprise?._user?.id === loggedInUser?.id;
      }
      return true;
    })
  );

  const requests = useSelector((state: RootState) =>
    state.requestState.requests?.filter((req: any) => {
      if (hasRole(loggedInUser, ["ENTERPRISE"])) {
        return req?._enterprise?.id === loggedInUser?.id;
      }
      if (hasRole(loggedInUser, ["INSTITUTION"])) {
        return req?.userId === loggedInUser?.id;
      }
      return true;
    })
  );

  const stateRequest = useMemo(()=>{
    const d = Object.keys(PROJECT_STATUS).reduce((init:Any , curr:string)=>{
      const stateRequest = requests.filter((el:Any)=>el?.etatFinancement === curr)
      init[curr] = stateRequest?.length
      return init
    },{})
    return d
  },[requests])


  const financedProject = useMemo(
    () => requests.filter((el: Any) => el?.etatFinancement === "Soldé"),
    [requests]
  );


  const enterprisesClient = enterprises.filter(
    (val: any) => val.categorie !== "Bailleur de fonds"
  ).length;

  return (
    <>
      <ASSidebar />
      <div className="main_content px-3 py-3">
        <div className="header-area">
          <h3 className="section-title">Tableau de bord</h3>
        </div>

        <div className="mt-5">
          <div className="row mt-3">
            <ASDashboardHCard
              title="Entreprise enregistrée"
              value={enterprisesClient}
              icon="file"
              valueColor="text-primary"
            />
            <ASDashboardHCard
              title="Project(s) rejeté(s)"
              value={stateRequest?.[PROJECT_STATUS.REJECTED]}
              icon="file"
              valueColor="text-success"
            />
     
          </div>

          <div className="row mt-3">
            <ASDashboardHCard
              title="Montant de garanties octroyées"
              value={millify(
                requests.reduce(
                  (a: any, b: any) => a + Number(b.financeAccode || "0"),
                  0
                )
              )}
              icon="file"
              valueColor="text-danger"
            />
            <ASDashboardHCard
              title="Garanties octroyées"
              value={financedProject?.length}
              icon="file"
              valueColor="text-primary"
            />
            
          </div>

          <div className="row mt-3">
            <div className="col-md-6">
              <h5 className="text-muted">Projets par région</h5>
              <div className="row">
                <ASStatisticCard
                  data={[
                    {
                      title: "Adamaoua",
                      value: requests.filter(
                        (val: any) => val.region === "Adamaoua"
                      ).length,
                    },
                    {
                      title: "Centre",
                      value: requests.filter(
                        (val: any) => val.region === "Centre"
                      ).length,
                    },
                    {
                      title: "Est",
                      value: requests.filter((val: any) => val.region === "Est")
                        .length,
                    },
                    {
                      title: "Extrême-Nord",
                      value: requests.filter(
                        (val: any) => val.region === "Extrême-Nord"
                      ).length,
                    },
                    {
                      title: "Littoral",
                      value: requests.filter(
                        (val: any) => val.region === "Littoral"
                      ).length,
                    },
                  ]}
                  colSize="6"
                />
                <ASStatisticCard
                  data={[
                    {
                      title: "Nord",
                      value: requests.filter(
                        (val: any) => val.region === "Nord"
                      ).length,
                    },
                    {
                      title: "Nord-Ouest",
                      value: requests.filter(
                        (val: any) => val.region === "Nord-Ouest"
                      ).length,
                    },
                    {
                      title: "Ouest",
                      value: requests.filter(
                        (val: any) => val.region === "Ouest"
                      ).length,
                    },
                    {
                      title: "Sud",
                      value: requests.filter((val: any) => val.region === "Sud")
                        .length,
                    },
                    {
                      title: "Sud-Ouest",
                      value: requests.filter(
                        (val: any) => val.region === "Sud-Ouest"
                      ).length,
                    },
                  ]}
                  colSize="6"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="row mt-4">
                <ASStatisticCard
                  title="Secteur d'activité"
                  badge="success"
                  data={[
                    ...ACTIVITY_SECTOR.map((el)=>({
                      title:el,
                      value:enterprises.filter(
                            (val: any) => val.sector === el
                          ).length,
                    }))
                  ]}
                  colSize="6"
                />

                <ASStatisticCard
                  title="Projets par statuts"
                  badge="secondary"
                  data={Object.entries(stateRequest).map(([key,val])=>({
                      title:STATUS_LABEL[key],
                      value:val
                  }))}
                  colSize="6"
                />
              </div>
            </div>
          </div>

          <div className="row mt-5 mx-3">
            <img src="/assets/img/reunion.jpeg" height="500" width="100%" />
          </div>

        </div>
        <div className="text-center">
          <a href="https://cybexai.com" target="_blank" className="mt-5">
            <img src="/assets/img/cybex_logo.png" height="60" />
          </a>
        </div>
      </div>
    </>
  );
};
